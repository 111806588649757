import React, { useEffect} from "react" 
import $ from 'jquery'

const SearchBarOU = () => { 

   const searchResultsPage = "/search/";
  
   useEffect(() => {
       
       setTimeout(function(){
            $('#ou-search--header').find('input').attr('placeholder','Search');
       }, 1000);

   })
    
   return ( 

        <div id="ou-search--header">
            <label htmlFor="gsc-i-id1" className="ou-sr-only" data-translate="true">Search</label>
            <div className="gcse-searchbox-only" data-queryparametername="q" data-enableautocomplete="true" data-autocompletemaxcompletions="5" data-resultsurl={searchResultsPage}></div>
        </div>
    ) 
 } 
 
 export default SearchBarOU

