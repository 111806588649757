import React from "react";
import { IsEmpty } from '../../helpers/HelperFunctions.js'

export default function PublishedByAuthor(props) { 
   
    if (IsEmpty(props.Authors)) {
      return null  
    } else {
            
    return ( 
            
        <>               
            {props.Authors.map(({ name, link, id }, i) => (
                
                    <React.Fragment key={"author-" + id}>                   
                        {!IsEmpty(link) ? (   
                            <a href="#" data-modal={"#ou-modal-popup"+id} className="ou-view-profile-modal">{name}</a>                                                    
                        ) : (
                            <span>{name}</span>
                        )}

                        {i + 1 !== props.Authors.length && ",\u00A0"}                    
                    </React.Fragment>
                
            ))}

        </>
        )
    
    }
}
