import React from "react"
import Button from "../Button"

import Interactive from "../../images/BBC_Interactive_white.svg"
import Download from "../../images/BBC_Download_white.svg"
import Challenge from "../../images/BBC_Challenge_white.svg"
import Media from "../../images/BBC_Media_white.svg"
import Documentation from "../../images/BBC_Documentation_white.svg"


export default function LayoutLinkButton(props) {

    const _buttonDesignType = props.content.elements.button_design.value[0].name;
    const _textBefore = props.content.elements.text_before_button.value;
    const _textAfter = props.content.elements.text_after_button.value;
    const _link = props.content.elements.link_url.value;
    const _buttonText = props.content.elements.button_text.value;
    const _linkType = props.content.elements.link_type.value[0].name;
    const _ariaLabel = props.content.elements.aria_text.value;
    const _target = (props.content.elements.open_in_new_window_.value[0].name === "Yes") ? "_blank" : "_self";
    const _key = props.content.id;


    return (

        <React.Fragment>

            {(_buttonDesignType === 'Transcript') && 

                <div>
                <p className='ou-accordion-article-sub-section-description'>
                {_textBefore}
                &nbsp;
                <Button 
                    link={_link}
                    text={[<i className="fa fa-bars" key={_key}></i>, _buttonText]}
                    class="ou-link--primary ou-link--transcript"
                    target={_target}
                    linkType={_linkType}
                    label={_ariaLabel}
                />
                &nbsp;
                {_textAfter}
                </p>
                </div>
            }


            {_buttonDesignType === "Visit Site" && 

                <div>
                <p className='ou-accordion-article-sub-section-description'>
                {_textBefore}
                &nbsp;
                <Button 
                    link={_link}
                    text={[<i className='fa fa-chevron-right' key={_key}></i>, _buttonText ]}
                    class="ou-link--primary ou-link--inline"
                    target={_target}
                    linkType={_linkType}
                    label={_ariaLabel}
                />
                &nbsp;
                {_textAfter}
                </p>
                </div>

            }

            {(_buttonDesignType === 'Download Pack') && 

                <div>
                <p className='ou-accordion-article-sub-section-description'>
                {_textBefore}
                &nbsp;
                <Button 
                    link={_link}
                    text={[<img src={Download} width="30" alt="" key={_key}/>, _buttonText ]}
                    class="ou-link--primary ou-link--inline"
                    target={_target}
                    linkType={_linkType}
                    label={_ariaLabel}
                    _key={_key}
                />
                &nbsp;
                {_textAfter}
                </p>
                </div>
            }

            {(_buttonDesignType === 'Open Interactive') && 

                <div>
                <p className='ou-accordion-article-sub-section-description'>
                {_textBefore}
                &nbsp;
                <Button 
                    link={_link}
                    text={[<img src={Interactive} width={30} alt="" key={_key}/>, _buttonText]}
                    class="ou-link--primary ou-link--inline"
                    target={_target}
                    linkType={_linkType}
                    label={_ariaLabel}
                />
                &nbsp;
                {_textAfter}
                </p>
                </div>

            }

            {(_buttonDesignType === 'Challenge Yourself') && 

                <p className='ou-accordion-article-sub-section-description'>
                {_textBefore}
                &nbsp;
                <Button 
                    link={_link}
                    text={[<img src={Challenge} width={30} alt="" key={_key}/>, _buttonText ]}
                    class="ou-link--primary ou-link--inline"
                    target={_target}
                    linkType={_linkType}
                    label={_ariaLabel}
                />
                &nbsp;
                {_textAfter}
                </p>
            }

            {(_buttonDesignType === 'Media Content') && 

                <p className='ou-accordion-article-sub-section-description'>
                {_textBefore}
                &nbsp;
                                
                <Button 
                    link={_link}
                    text={[<img src={Media} width={30} alt="" key={_key}/>, _buttonText ]}
                    class="ou-link--primary ou-link--inline ou-link--trailing-icon"
                    target={_target}
                    linkType={_linkType}
                    label={_ariaLabel}
                />
                &nbsp;
                {_textAfter}
                </p>
            }


            {(_buttonDesignType === 'Documentation') && 

                <p className='ou-accordion-article-sub-section-description'>
                {_textBefore}
                &nbsp;
                <Button 
                    link={_link}
                    text={[<img src={Documentation} width={30} alt="" key={_key}/>, _buttonText]}
                    class="ou-link--primary ou-link--inline"
                    target={_target}
                    linkType={_linkType}
                    label={_ariaLabel}
                />
                &nbsp;
                {_textAfter}
                </p>

            }
           
        </React.Fragment>
    )
}