import React from "react"
import { IsEmpty } from "../../helpers/HelperFunctions"


export default function LayoutAncillaryPrimaryHeader(props) {
    
    return (
        
        <>
            {!IsEmpty(props.Header) && 
                <h2 className="ou-main-header" tabIndex="0">
                    {props.Header}
                </h2>
            }

            {!IsEmpty(props.HeaderDesc) && 
                <h2 className="ou-main-header-description" tabIndex="0">
                    {props.HeaderDesc}
                </h2>
            }
        </>

    )

}