import React from "react"
import { ImageElement } from "@kontent-ai/gatsby-components"
import { IsEmpty } from '../../helpers/HelperFunctions.js'

export default function Image(props) {
    
    if (IsEmpty(props.image) || IsEmpty(props.image[0].elements.image.value))
        return null

    const image = props.image[0].elements.image.value[0];

    const imageMaxWidth= (props.MaxWidth) ? props.MaxWidth : image.width;
    const imageHeight=(props.MaxHeight) ? props.MaxHeight : image.height;
    const imageLayout=(props.Layout) ? props.Layout : "constrained";

    let altText= (IsEmpty(image.description)) ? "" : image.description;
    altText = (props.blankAltText === "true") ? "" : altText;

    const imageLoading = (IsEmpty(props.loading)) ? "lazy" : props.loading;


    return (
        <React.Fragment>
            
            <ImageElement 
                image={image} 
                width={imageMaxWidth} 
                height={imageHeight} 
                alt={altText} 
                layout={imageLayout}
                className="ou-object-fit"
                loading={imageLoading}
            />

        </React.Fragment>  
    )
} 