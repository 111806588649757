import React from "react"
import RenderMedia from "../AssetManagement/RenderMedia";
import { IsEmpty } from "../../helpers/HelperFunctions"
import ModularContentProcessor from "../StructuredContent/ModularContentProcessor.js"

export default function LayoutAboutTheProgramme(props) {
       
    if (IsEmpty(props.title) && IsEmpty(props.text) && IsEmpty(props.mediaAssets))
        return null;


    return (
        
        <>
            {!IsEmpty(props.title) &&
                <h2 className="ou-about-the-programme-title">{props.title}</h2>
            }
            
            <div className="ou-about-the-programme-container">

                {!IsEmpty(props.mediaAssets) &&
                    <div className="ou-about-the-programme-image-container">
                        <RenderMedia 
                            mediaArray={props.mediaAssets} 
                            imageClassName="ou-about-the-programme-image"
                            showCaptions="true"
                        />
                    </div>
                }
                                                
                {!IsEmpty(props.text) &&
                    <div className="ou-about-the-programme-content">
                        <ModularContentProcessor 
                            richText={props.text} 
                            layoutSection="AboutTheProgrammeLayout"/>
                    </div>
                }
                
            </div>
       </>     
    )

}