import React from "react"
import LayoutFiveFactsCarousel from "./LayoutFiveFactsCarousel.js"
import LayoutExpandedArticleCarousel from "./LayoutExpandedArticleCarousel.js"

export default function LayoutCarouselController(props) {
         
    const _title = props.content.elements.carousel_title;
    const _listOfCarouselItems = props.content.elements.list_of_carousel_items;
    
    return (
        
        <>
            {props.layoutSection === "FiveFacts" &&
                <LayoutFiveFactsCarousel
                    carouselTitle={_title}
                    listOfCarouselItems={_listOfCarouselItems}
                    articleIndex={props.articleIndex}
                />
            }

            {props.layoutSection === "ExpandedArticle" &&
                <LayoutExpandedArticleCarousel
                    carouselTitle={_title}
                    listOfCarouselItems={_listOfCarouselItems}
                    articleIndex={props.articleIndex}
                />
            }

        </>     
    )

}