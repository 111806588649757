import React from "react"
import { IsEmpty } from "../../helpers/HelperFunctions"
import ModularContentProcessor from "../StructuredContent/ModularContentProcessor.js";
import RenderMedia from "../AssetManagement/RenderMedia";


export default function LayoutExpandedArticle(props) {
   
    const layoutSection = "InfographicThumbnailCardArticle";

    return (

        <>
            <div className="ou-article-thumbnail-card">
                <div className="ou-article-thumbnail-image-container">
                    {/* Display media assets.. May have a combination of iFrameLink, image or both. */}
                    <RenderMedia 
                        mediaArray={props.mediaAssets} 
                        showCaptions="false"
                        imageClassName="ou-article-thumbnail-image"
                        />
                    </div>
                <div className="ou-article-thumbnail-content">

                    {!IsEmpty(props.title) &&
                        <h4 className="ou-article-thumbnail-title">{props.title}</h4>
                    }
                                        
                    {!IsEmpty(props.richText) &&
                        <ModularContentProcessor 
                            richText={props.richText}
                            layoutSection={layoutSection}
                        />
                    }
                   
                </div>

            </div>
        </>

    )
}


