import React from "react";
import { IsEmpty, removeParagraphTags  } from '../../helpers/HelperFunctions.js'
import Image from "../AssetManagement/Image.js"
import { StaticImage} from "gatsby-plugin-image"


export default function PublishedByAuthorModal(props) {
    
    const modalid = "ou-modal-popup"+ props.modalid
    const profileLink = removeParagraphTags(props.linkUrl);

    if (IsEmpty(props.expertName) || IsEmpty(props.expertJobTitle) || IsEmpty(props.expertBio)){
      return null  
    } else {
            
    return ( 
            
        <React.Fragment>

              {/* modal window */}
            <div className="ou-expert-modal-overlay"></div>
            <div className="ou-expert-modal-wrapper" id={modalid}>
                <div className="ou-expert-modal-container">
                    <button className="ou-modal-close-button" aria-label="Close"></button>
                    <div className="ou-expert-modal-body">
                        <div className="ou-expert-image">
                            <Image 
                                image={props.profile_picture}
                                MaxWidth="533"
                                MaxHeight="490"
                            /> 
                        </div>

                        <div className="ou-expert-modal-content-container">
                            <div className="ou-expert-modal-content-header">
                                <div className="ou-expert-modal-content-header-logo">
                                    <StaticImage src="../../images/OU_Master_Logo.png" alt="The Open University"/>
                                </div>
                                <div className="ou-expert-title-container">      
                                    <span className="ou-expert-name">{props.expertName}</span>
                                    <span className="ou-expert-title">{props.expertJobTitle}</span>
                                </div>
                            </div>
                            <div className="ou-expert-modal-content">
                                <span className="ou-expert-details"><div dangerouslySetInnerHTML={{ __html: props.expertBio }} /></span>
                                
                                {!IsEmpty(profileLink) && 
                                    <a className="ou-link--expert-profile" href={profileLink} target="_blank" rel="noopener noreferrer">View full OU profile</a>                                                 
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>
        )
    
    }
}