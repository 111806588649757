import React from "react"
import Image from "../AssetManagement/Image.js"
import { IsEmpty, SetVideoTitle } from "../../helpers/HelperFunctions"
import ModularContentProcessor from "../StructuredContent/ModularContentProcessor.js";
import Button from "../../components/Button.js";

export default function LayoutTiledAsset(props) {
       
    if (IsEmpty(props.title) && IsEmpty(props.richText) && IsEmpty(props.image) && IsEmpty(props.mediaAssets))
        return null;

    return (
        
        <>
            <section className="ou-expanded-article-wrapper">
                <div className="ou-expanded-article-container ou-video-content-container">
                    <div className="ou-expanded-article-video-content">
                        <h3 className="ou-expanded-article-title">{props.title}</h3>

                        <ModularContentProcessor 
                            richText={props.richText}
                            layoutSection="TiledAssetExpandedArticleDescription"
                        />   

                        <div className="ou-article-video-wrapper">
                            <div className="ou-programme-article-iframe-container" tabIndex="0">
                                <span dangerouslySetInnerHTML={{ __html: SetVideoTitle(props.mediaAssets.value[0].elements.external_iframe_link.value, props.mediaAssets.value[0].elements.video_title.value)}} />
                                {!IsEmpty(props.mediaAssets.value[0].elements.video_duration.value) &&
                                    <div className="ou-programme-article-iframe-overlay"><span>{props.mediaAssets.value[0].elements.video_duration.value}</span><i className="fa fa-play"></i></div>
                                }
                            </div>
                            <div className="ou-article-video-footer">
                                <p>{props.mediaAssets.value[0].elements.video_caption_text.value}</p>
                            </div>   

                            {!IsEmpty(props.mediaAssets.value[0].elements.transcript.value) &&
                                <div>
                                    <Button 
                                        link={props.mediaAssets.value[0].elements.transcript.value[0].url}
                                        text={[<i key="tile-i-key" className='fa fa-bars'></i>, "Transcript"]}
                                        class="ou-link--primary ou-link--transcript"
                                        target="_blank"
                                        linkType="External"
                                        label={!IsEmpty(props.mediaAssets.value[0].elements.transcript.value[0].description) ? props.mediaAssets.value[0].elements.transcript.value[0].description : "Read transcript"}
                                    />
                                </div>
                            }

                        </div>                     
                    </div>

                    <div className="ou-expanded-article-image">
                        <Image 
                            image={props.image}
                        />
                    </div>
                </div>
            </section>

       </>     
    )

}