import React from "react"
import { IsEmpty } from '../../helpers/HelperFunctions.js'
import OUVideo from "../AssetManagement/OUVideo";
import { ImageElement} from "@kontent-ai/gatsby-components"
import CopyrightImage from "../CopyrightImage/CopyrightImage.js"
import ImageCaption from "../ImageCaption/ImageCaption.js"


export default function RenderMedia({mediaArray,
                                     imageClassName='ou-expanded-article-image',
                                     showCaptions = 'false',
                                     captionsInnerContainerClassName='ou-expanded-article-image-inner-container',
                                     imageCaptionClassName='ou-programme-article-image-subtext',
                                     imageAndVideoContainerClassname='ou-programme-article-media',
                                     videoOnlyContainerClassName='ou-programme-article-media',
                                     objectFitSetting='cover'}) {

    
    var iFrameLinkCount = 0;
    var imageCount = 0;


    // How many videos are there in this section
    mediaArray.value.forEach(assetObject => {
        if (!IsEmpty(assetObject.elements.external_iframe_link.value)) {
            iFrameLinkCount=iFrameLinkCount+1;
        }
    });
        
        
    // Pick out any assets with iFrame Links.
    const videos = mediaArray.value.map( ( video ) => (

        <React.Fragment key={video.id}>
            <OUVideo 
                videoAsset={video}
                iFrameContainerClass='ou-programme-article-iframe-container'
                iFrameOverlayClass='ou-programme-article-iframe-overlay'
            />
        </React.Fragment>
    ));
        
        
    mediaArray.value.forEach(assetObject => {
        if (!IsEmpty(assetObject.elements.image.value)) {
            imageCount=imageCount+1;
        }
    });
        
    // Pick out any assets with image Links.
    const images = mediaArray.value.map( ( _image ) => (

        // Build markup for each image here.
        <React.Fragment key={_image.id}>
            
            {!IsEmpty(_image.elements.image.value) && 
                <div className={imageClassName}>

                    {/* There's an extra DIV container if there is a caption accompanying the image. */}
                    {showCaptions === 'true' && 
                    
                        <>
                        {!IsEmpty(_image.elements.caption.value) &&
                            <React.Fragment>
                            <div className={captionsInnerContainerClassName}>
                                <ImageElement 
                                    image={_image.elements.image.value[0]} 
                                    width={_image.elements.image.value[0].width} 
                                    height={_image.elements.image.value[0].height} 
                                    alt={_image.elements.image.value[0].description} 
                                    className="ou-object-fit"
                                    objectFit={objectFitSetting}
                                />
                            </div>
                                <CopyrightImage 
                                    CopyrightCaption={_image.elements.caption.value}
                                />
                                <ImageCaption 
                                    ImageCaption={_image.elements.image_caption.value}
                                />
                            </React.Fragment>
                        }
                        {/*  Possible that there is no caption entered... */}
                        {IsEmpty(_image.elements.caption.value) &&
                            <React.Fragment>
                                <ImageElement 
                                    image={_image.elements.image.value[0]} 
                                    width={_image.elements.image.value[0].width} 
                                    height={_image.elements.image.value[0].height} 
                                    alt={_image.elements.image.value[0].description} 
                                    className="ou-object-fit"
                                    objectFit={objectFitSetting}
                                />
                                <ImageCaption 
                                    ImageCaption={_image.elements.image_caption.value}
                                />
                            </React.Fragment>
                        }
                        </>
                    }

                    {showCaptions === 'false'  &&
                        <React.Fragment>
                            <ImageElement 
                                image={_image.elements.image.value[0]} 
                                width={_image.elements.image.value[0].width} 
                                height={_image.elements.image.value[0].height} 
                                alt={_image.elements.image.value[0].description} 
                                className="ou-object-fit"
                                objectFit={objectFitSetting}
                            />
                            <CopyrightImage 
                                CopyrightCaption={_image.elements.caption.value}
                            />
                        </React.Fragment>
                    }

                </div>
            }
        </React.Fragment>

    ));
                        
        
    return (
        <>
            {/* Markup is slightly different if we have both video and image links */}
            {(iFrameLinkCount > 0 && imageCount > 0) &&
                <>
                    <div className={imageAndVideoContainerClassname}>
                        {videos}
                        {images}
                    </div>
                </>
            }
            {(iFrameLinkCount > 0 && imageCount === 0) &&
                <>
                <div className={videoOnlyContainerClassName}>
                  {videos}
                </div>
                </>
            }
            {(iFrameLinkCount === 0 && imageCount > 0) &&
                <>
                  {images}
                </>
            }
        </>
    )
} 