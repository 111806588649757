import React  from "react"
import ModularContentProcessor from "../StructuredContent/ModularContentProcessor.js";
import Image from "../AssetManagement/Image.js"
import { IsEmpty, IsTitleTextImage, IsQuizLayout, IsVideoPlaylistLayout, IsCarouselLayout, IsEmptyArray, cleanParagraphs} from "../../helpers/HelperFunctions"
import RenderMedia from "../AssetManagement/RenderMedia.js";
import CopyrightImage from "../CopyrightImage/CopyrightImage.js"
import ImageCaption from "../ImageCaption/ImageCaption.js"
import LayoutQuiz from "./LayoutQuiz.js"
import LayoutVideoPlaylist from "./LayoutVideoPlaylist.js"
import LayoutCarousel from "./LayoutCarousel.js"

export default function LayoutAccordion(props) {
    
    const accordionContainerClass = (IsEmpty(props.accordionBackgroundLayout.value)) ? "odd" :  props.accordionBackgroundLayout.value[0].name.toLowerCase();
    const openAccordionButtonText = (IsEmpty(props.open_accordion_button_text.value) ? "VIEW COMPLETE ARTICLE" : props.open_accordion_button_text.value);

    return (
        
        <>
            <div className={"ou-accordion-article-container accordion-article-" + accordionContainerClass}> 
                <div className="ou-accordion-article-main">
                    {!IsEmptyArray(props.media) &&
                        <div className="ou-accordion-article-image-container">
                            <div className="ou-accordion-article-image">
                                <Image 
                                    image={props.media.value}
                                />
                            </div>
                            {!IsEmpty(props.media.value) &&
                                <>
                                    <CopyrightImage 
                                        CopyrightCaption={props.media.value[0].elements.caption.value}
                                    />
                                    <ImageCaption 
                                        ImageCaption={props.media.value[0].elements.image_caption.value}
                                    />
                                </>
                            }
                        </div>
                    }
                    <div className="ou-accordion-article-summary">
                        <h2 className="ou-accordion-article-title">{props.title.value}</h2>

                        <p className="ou-accordion-article-description">
                            <span>{props.description.value}</span>
                        </p>

                        <button className="ou-accordion-toggle" data-text-show={openAccordionButtonText} data-text-swap="Close" aria-label={"Open " + props.title.value + " article"} aria-expanded="false">{openAccordionButtonText}</button>
                    </div>
                </div>

                <div className="ou-accordion-content">

                    {!IsEmpty(props.introduction.value) &&
                        <div>
                            <div className="ou-article-description-col-2" dangerouslySetInnerHTML={{__html: props.introduction.value}} />                           
                        </div>
                    }

                    {props.body.modular_content.map(({ elements, id, system }, index) => {
                        // Extract the content from the body element
                        

                        return (
                            <div className="ou-accordion-article-sub-section-wrapper" key={"divAccordionWrapper-" + id}>

                                {/* We need to process a quiz component separately, so check for the quiz layout */}
                                {IsQuizLayout(system.type) &&
                                    <LayoutQuiz
                                        iFrameLink={elements.quiz_iframe_link}
                                        title={elements.title}
                                        body={elements.body}
                                    />
                                }

                                {/* We need to process a carousel component separately, so check for the carousel layout */}
                                {IsCarouselLayout(system.type) &&
                                    <LayoutCarousel
                                        carouselTitle={elements.carousel_title}
                                        listOfCarouselItems={elements.list_of_carousel_items}
                                        articleIndex={props.articleIndex}
                                    />
                                }

                                {/* We need to process a video playlist component separately, so check for the VP layout */}
                                {IsVideoPlaylistLayout(system.type) &&
                                    <LayoutVideoPlaylist
                                        listOfVideos={elements.list_of_videos}
                                        playlistTitle={elements.video_playlist_title}
                                        playlistBody={elements.video_playlist_body}
                                        duration_summary={elements.video_playlist_duration_summary}
                                        overlayID={id}
                                    />
                                }


                                {(!IsVideoPlaylistLayout(system.type) && !IsCarouselLayout(system.type) && !IsEmpty(elements.title.value) && IsTitleTextImage(system.type)) &&
                                    <h3 className="ou-accordion-article-sub-section-title">{elements.title.value}</h3>
                                }

                                {IsTitleTextImage(system.type) &&

                                    <div className={"ou-accordion-article-sub-section sub-section-" + ((index + 1) % 2 === 0 ? "even" : "odd")}> {/* Needs to be odd/even */}

                                        {(!IsEmptyArray(elements.asset) || !IsEmpty(elements.quote_text.value)) &&
                                            <div className="ou-accordion-article-sub-section-image-container">

                                                {/* Display media assets.. May have a combination of iFrameLink, image or both. */}
                                                <RenderMedia
                                                    mediaArray={elements.asset}
                                                    imageClassName="ou-accordion-article-sub-section-image"
                                                    showCaptions="true"
                                                    key={elements.asset.id}
                                                />

                                                {!IsEmpty(elements.quote_text.value) &&
                                                    <div className="ou-article-blockquote">
                                                        <blockquote>
                                                            <i className="fa fa-quote-left" aria-hidden="true"></i>
                                                            {elements.quote_text.value}
                                                            <i className="fa fa-quote-right" aria-hidden="true"></i>
                                                        </blockquote>
                                                    </div>
                                                }
                                            </div>
                                        }

                                        <div className="ou-accordion-article-sub-section-content">   

                                           
                                                <ModularContentProcessor
                                                    richText={elements.body}
                                                    layoutSection="AccordionSubDescription"
                                                />
                                            
                                            
                                            {/*<div dangerouslySetInnerHTML={{__html: elements.body.value}} /> */}
                                        </div>
                                    </div>
                                }
                                <hr className="ou-article-break" />
                            </div>

                        )
                    })}


                    <button className="ou-accordion-inner-toggle" aria-label={"Close " + props.title.value + " article"} aria-expanded="false">Close</button>

                </div>

            </div>
       
        </>        
    )

}