import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { IsEmpty } from '../../helpers/HelperFunctions.js'
import { ImageElement } from "@kontent-ai/gatsby-components"

export const PureChannelLogo = ({channelID='BBC 1', width="50", height, data }) => (

    <>

    {data.allKontentItemWebItemBbcLogo.edges.map(({ node }) => (
        <React.Fragment key={node.id}>
        {node.system.name === channelID &&

            <>
            {IsEmpty(width) &&
                <ImageElement image={node.elements.logo.value[0]} alt={node.elements.logo.value[0].description} loading="eager"/>
            }
            {(!IsEmpty(width) && IsEmpty(height)) &&
                <ImageElement image={node.elements.logo.value[0]} alt={node.elements.logo.value[0].description} width={width} loading="eager"/>
            }
            {(!IsEmpty(width) && !IsEmpty(height)) &&
                <ImageElement image={node.elements.logo.value[0]} alt={node.elements.logo.value[0].description} width={width} height={height} loading="eager"/>
            }
            </>
        }
        </React.Fragment>
    ))}  

    </>

) 


// Default exported component with Static Query - utilises 'pure' version above for rendering.
export const ChannelLogo = props => (
    <StaticQuery 
          query = {graphql `query {
            allKontentItemWebItemBbcLogo {
              edges {
                node {
                  id  
                  elements {
                    logo {
                      value {
                        description
                        height
                        name
                        size
                        type
                        url
                        width
                      }
                    }
                  }
                  system {
                    name
                  }
                }
              }
            }
          }`}
  
          render = { data => <PureChannelLogo channelID={props.channelID} width={props.width} height={props.height} data={data} />}
      />
  )
  
  
  export default ChannelLogo
  