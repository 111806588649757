import React from "react"
import { IsEmpty } from "../../helpers/HelperFunctions"


export default function LayoutAncillaryAddress(props) {
    
    return (
        
        <>
            <address className="ou-contact-address" tabIndex="0">
                
                {!IsEmpty(props._addressLine1) && 
                    <>{props._addressLine1}<br/></>
                }
                {!IsEmpty(props._addressLine2) && 
                    <>{props._addressLine2}<br/></>
                }
                {!IsEmpty(props._addressLine3) && 
                    <>{props._addressLine3}<br/></>
                }
                {!IsEmpty(props._addressLine4) && 
                    <>{props._addressLine4}<br/></>
                }
                {!IsEmpty(props._addressLine5) && 
                    props._addressLine5
                }
            </address>
        </>

    )

}