// Handles the display of a single 'Explore The OU' data component.
import React from "react";
import Image from "../AssetManagement/Image.js"
import PropTypes from "prop-types"
import CopyrightImage from "../CopyrightImage/CopyrightImage.js"
import { IsEmpty } from "../../helpers/HelperFunctions"


export default function ExploreTheOU({imageObject = null,
                                     exploreLink = "https://www.open.ac.uk",
                                     exploreLinkText = "The Open University"}) {

    const ariaLabel = "Go to " + exploreLinkText + " page";
    
    return (
      <>
        <a aria-label={ariaLabel} href={exploreLink}></a>

          <div className="about-ou-element-image">
            {imageObject &&
              <Image image={imageObject} />
            }
          </div>
          {!IsEmpty(imageObject) &&
            <>
              <CopyrightImage 
                  CopyrightCaption={imageObject[0].elements.caption.value}
              />
            </>
          }
        
          <div className="about-ou-element-content">
            <h3 className="about-ou-element-title">{exploreLinkText}</h3>
          </div>
      </>
    )
}


ExploreTheOU.propTypes = {
  exploreLink: PropTypes.string,
  exploreLinkText: PropTypes.string,
  imageObject: PropTypes.array.isRequired,
}
