import React from "react"
import { RichTextElement, ImageElement} from "@kontent-ai/gatsby-components"
import { IsEmpty } from "../../helpers/HelperFunctions"
import LayoutTextWithEmbeddedAssets from "./LayoutTextWithEmbeddedAssets.js"
import Layout2Columns from "./Layout2Columns.js";
import LayoutCentralisedBlock from "./LayoutCentralisedBlock.js"
import LayoutInfographicIconsArticle from "./LayoutInfographicIconsArticle.js"
import LayoutAccordion from "./LayoutAccordion.js"
import LayoutRecentProjects from "./LayoutRecentProjects";
import LayoutAncillaryPrimaryHeader from "./LayoutAncillaryPrimaryHeader.js"
import LayoutAncillarySubHeader from "./LayoutAncillarySubHeader.js"
import LayoutUnorderedList from "./LayoutUnorderedList.js"
import LayoutAncillarySubTitle from "./LayoutAncillarySubTitle.js"
import LayoutAncillaryAddress from "./LayoutAncillaryAddress.js"
import LayoutLinkButton from "./LayoutLinkButton.js"
import LayoutVideoPlaylist from "./LayoutVideoPlaylist.js"
import LayoutQuizController from "./LayoutQuizController.js"
import LayoutPublishedBy from "./LayoutPublishedBy.js"
import LayoutCarouselController from "./LayoutCarouselController.js";

export default function ModularContentProcessor(props) {
    
    
    // Mandatory: A rich-text field to process!
    if(IsEmpty(props.richText))
        return null;
   
    const richTextElement = props.richText;


    return (
        <React.Fragment>

            {/* Display an Article in STANDARD format.  */}
            <RichTextElement
                value={richTextElement.value}
                images={richTextElement.images}
                links={richTextElement.links}
                linkedItems={richTextElement.modular_content}
                
                // Embedded image Resolver
                resolveImage={image => {
                    return (
                    <ImageElement
                        image={image}
                        alt={image.description ? image.description : image.name}
                        width={image.width > 1200 ? 950 : image.width}
                        style={{display:"block", marginLeft:"15%", marginRight:"15%", marginBottom:"15px"}}
                    />
                    )
                }}

                // Embedded Links resolver.
                /*resolveLink={(link, domNode) => {
                    return (
                        <Link to={`/${link.type}/partner/bannana/${link.url_slug}`}>
                        {domNode.children[0].data}
                        </Link>
                    )
                    }}*/


                // Enbedded content types resolver.
                resolveLinkedItem={linkedItem => {
                                        
                    let markUp = "";
                    switch (linkedItem.system.type) {

                        case 'layout_title_text_image': {

                            markUp = <LayoutTextWithEmbeddedAssets 
                                        layoutSection={props.layoutSection}
                                        content={linkedItem}
                                    />
                            
                            break;
                        }

                        case 'layout___link_button': {

                            markUp = <LayoutLinkButton 
                                        layoutSection={props.layoutSection}
                                        content={linkedItem}
                                    />
                            break;
                        }

                        case 'layout___article_icon_card': {

                            markUp = <LayoutInfographicIconsArticle 
                                        layoutSection={props.layoutSection}
                                        mediaAssets={linkedItem.elements.icon_image}
                                        title={linkedItem.elements.title.value}
                                        description={linkedItem.elements.description.value}
                                    />
                            break;
                        }

                        case 'layout___2_columns': {

                            markUp = <Layout2Columns 
                                        layoutSection={props.layoutSection}
                                        titleText={linkedItem.elements.section_title}
                                        column1Title={linkedItem.elements.column_1_title}
                                        column2Title={linkedItem.elements.column_2_title}
                                        column1Body={linkedItem.elements.column_1_body}
                                        column2Body={linkedItem.elements.column_2_body}
                                        presentationMode={linkedItem.elements.presentation.value[0].name}
                                    />
                            break;
                        }
                        
                        case 'layout___centralised_block' : {

                            markUp = <LayoutCentralisedBlock 
                                            layoutSection={props.layoutSection}
                                            richText={linkedItem.elements.body}
                                    />
                            break;
                        }

                        case 'layout___accordion' : {

                            markUp = <LayoutAccordion 
                                        body={linkedItem.elements.body}
                                        title={linkedItem.elements.title}
                                        description={linkedItem.elements.description}
                                        introduction={linkedItem.elements.introduction}
                                        open_accordion_button_text={linkedItem.elements.open_accordion_button_text}
                                        media={linkedItem.elements.media}
                                        accordionBackgroundLayout={linkedItem.elements.background___layout}
                                        articleIndex={props.articleIndex}
                                    />
                            break;
                        }

                        case 'layout___programme_lister' : {

                            markUp = <LayoutRecentProjects 
                                        broadcastChannel={linkedItem.elements.broadcast_channels__channels}
                                        listOfProgrammes={linkedItem.elements.list_of_programmes}
                                        keyValue={linkedItem.id}
                                    />
                            break;
                        }


                        case 'layout___ancillary___wrapper' : {
                            markUp = (<div className="ou-content-wrapper">
                                            <ModularContentProcessor 
                                                richText={linkedItem.elements.content}
                                            />
                                        </div>)
                            break;
                        }
                        case 'layout___ancillary___primary_header' : {
                            markUp = <LayoutAncillaryPrimaryHeader
                                        Header={linkedItem.elements.header.value}
                                        HeaderDesc={linkedItem.elements.header_description.value}
                                    />
                            break;
                        }
                        case 'layout___ancillary___sub_heading' : {
                            markUp = <LayoutAncillarySubHeader
                                        SubHeader={linkedItem.elements.sub_heading.value}
                                    />
                            break;
                        }
                        case 'layout___ancillary___paragraph_container' : {
                            markUp = (<div className="ou-main-sub-paragraph-container" tabIndex="0" >
                                        <ModularContentProcessor
                                            richText={linkedItem.elements.paragraph_text} 
                                            layoutSection="AncillaryParagraph"
                                        />
                                    </div>)
                            break;
                        }
                        case 'layout___ancillary___sub_title' : {
                            markUp = <LayoutAncillarySubTitle
                                        subTitle={linkedItem.elements.sub_title.value}
                                        _question={linkedItem.elements.add_question_icon_}
                                      />
                            break;
                        }
                        case 'layout___ancillary___address' : {
                            markUp = <LayoutAncillaryAddress
                                        _addressLine1={linkedItem.elements.address_line_1.value}
                                        _addressLine2={linkedItem.elements.address_line_2.value}
                                        _addressLine3={linkedItem.elements.address_line_3.value}
                                        _addressLine4={linkedItem.elements.address_line_4.value}
                                        _addressLine5={linkedItem.elements.address_line_5.value}
                                      />
                            break;
                        }
                        case 'layout___unordered_list' : {
                            markUp = <LayoutUnorderedList
                                        listHeader={linkedItem.elements.list_header.value}
                                        listItems={linkedItem.elements.list_items}
                                     />
                            break;
                        }
                        case 'layout___video_playlist' : {
                            markUp = <LayoutVideoPlaylist
                                        listOfVideos={linkedItem.elements.list_of_videos}
                                        playlistTitle={linkedItem.elements.video_playlist_title}
                                        playlistBody={linkedItem.elements.video_playlist_body}
                                        duration_summary={linkedItem.elements.video_playlist_duration_summary}
                                        overlayID={linkedItem.id}
                                     />
                            break;
                        }
                        case 'layout___quiz' : {
                            markUp = <LayoutQuizController
                                        layoutSection={props.layoutSection}
                                        content={linkedItem}
                                     />
                            break;
                        }
                        case 'layout___published_by' : {
                            markUp = <LayoutPublishedBy
                                        publishDate={linkedItem.elements.publication_date.value}
                                        OUExperts={linkedItem.elements.published_by_ou_authors} 
                                        guestAuthor1Name={linkedItem.elements.guest_author_name_1}
                                        guestAuthor1Title={linkedItem.elements.guest_author_title_1.value[0]}
                                        guestAuthor2Name={linkedItem.elements.guest_author_name_2}
                                        guestAuthor2Title={linkedItem.elements.guest_author_title_2.value[0]}
                                        guestAuthor3Name={linkedItem.elements.guest_author_name_3}
                                        guestAuthor3Title={linkedItem.elements.guest_author_title_3.value[0]}
                                     />
                            break;
                        }

                        case 'layout___carousel' : {
                            markUp = <LayoutCarouselController
                                        layoutSection={props.layoutSection}
                                        content={linkedItem}
                                        articleIndex={props.articleIndex}
                                     />
                            break;
                        }
                        

                        default: {

                            markUp = <React.Fragment>
                                        <p>WARNING: Unrecognised linkedItem in RichText body</p>                    
                                     </React.Fragment>
                            break;
                        }
                          
                    }
                     return markUp;
                 }}

                // Dom Node Resolver - Any elements not handled by the above resolvers are handled here.
                resolveDomNode={(domNode, domToReact) => {

                    return OUDomResolver(domNode, props.layoutSection, domToReact);

                }}
            />
            
        </React.Fragment>
    )

}


// Handles the allocation of class names to DOM elements.
function OUDomResolver( domNode, layoutSection, domToReact) {

    var paragraphClass='';


    const options = {
        replace: domNode => {

            

            if (domNode.name === "strong") {
    
                switch (layoutSection) {
                    case 'AccordionSubDescription':
                        return <i className='ou-article-text-highlight'>{domToReact(domNode.children)}</i>;
        
                    default:
                        break;
                }
            }    

            if (domNode.name === "a") {
    
                switch (layoutSection) {
                    case 'AccordionSubDescription':
                        return <a className='ou-article-text-highlight' href={domNode.attribs["href"]}>{domToReact(domNode.children)}</a>;
                    case 'UnorderedListComponent':
                        return <a className='ou-external-link--primary' href={domNode.attribs["href"]}>{domToReact(domNode.children)}</a>;
                    case 'AncillaryParagraph':
                        return <a className='ou-external-link--primary' href={domNode.attribs["href"]}>{domToReact(domNode.children)}</a>;
                    default:
                        break;
                }
            }  
            
            if (domNode.name === "li") {

                switch (layoutSection) {
                    case 'AboutPriorityUnorderedLists':
                        return <dt>- {domToReact(domNode.children)}</dt>

                    case 'AboutGetInvolved':
                        return <dt>- {domToReact(domNode.children)}</dt>

                    default:
                        break;
                }
            }


        }
    }; 

    // PARAGRAPH tags require a specific class assigning.
    if (domNode.name === "p") {

        switch (layoutSection) {

            case 'AboutTheOUCard':
                paragraphClass='ou-website-card-description';
                break;
            case 'RelatedProgrammeSummary':
                paragraphClass='ou-inner-article-description';
                break;
            case 'HeroBanner':
                paragraphClass='ou-bbc-hero-description';
                break;

            case 'Layout2ColumnBody':
            case 'CentralisedBlockText':
            case 'AboutIntroductionLinkedItem':
            case 'AboutIntroduction':
            case 'TiledAssetExpandedArticleDescription':
            case 'TiledArticleSummary':
            case 'InfographicIconsArticleParagraphs':
            case 'InfographicThumbnailArticleTitle':
            case 'ArticleIntro':                
            case 'ExpandedArticle':
                paragraphClass='ou-expanded-article-description';
                break;

            case 'Layout2ColumnSectionHeader':
                paragraphClass='ou-expanded-article-title';
                break;

            case 'ExpandedArticleSubtitle':
                paragraphClass='ou-expanded-article-subtitle';
                break;
            
            case 'FiveFacts':
                paragraphClass='ou-programme-article-description';
                break;
                
            case 'InfographicThumbnailCardArticle':
                paragraphClass='ou-article-thumbnail-description';
                break;
               
            case 'AccordionIntroduction':
                paragraphClass='ou-article-description-col-2';
                break;
            case 'AccordionSubDescription':
                paragraphClass='ou-accordion-article-sub-section-description';
                break;
                 
            case 'directory-listing':
                paragraphClass='ou-subsection-image-content';
                break;    
                
            case 'PartnershipProgrammes':    
            case 'LatestArticleLister':
                paragraphClass='ou-latest-article-description';
                break;

            case 'LatestArticlesSubArticle':
                paragraphClass='ou-sub-article-description';
                break

            case 'SubjectAreaFeaturedSection':
                paragraphClass='ou-article-directory-carousel-summary';
                break;

            case 'SubjectAreaLister':
                paragraphClass='ou-subject-area-description';
                break;

            case 'AboutGetInvolved':
                paragraphClass='ou-group-content';
                break;
            
            case 'TimelineSlideDesc':
                paragraphClass='ou-programme-timeline-slide-description';
                break;

            case 'TimelineDescription':
                paragraphClass='ou-programme-timeline-description';
                break;

            case 'SpotlightBannerDescription':
                paragraphClass='ou-bbc-article-banner-description';
                break;

            case 'LatestProgrammeBannerDescription':
                paragraphClass='ou-latest-article-description';
                break;
            case 'videoListParagraph':
                paragraphClass='ou-video-playlist-banner-description';
                break;
            case 'quizDescription':
                paragraphClass='ou-quiz-description';
                break;
            case 'SiteWideMessage':
                paragraphClass='ou-site-message-description';
                break;
            default:
                break;
        }
    }

    
    if (domNode.name === "h3") {

        switch (layoutSection) {

            case 'FiveFacts':
                return (
                    <div>
                    <h4 className='ou-programme-article-description-subtitle'>{domNode.children[0].data}</h4>                    
                    </div>
                )
                
            case 'CentralisedBlockText':
                return <h3 className='ou-expanded-article-title'>{domNode.children[0].data}</h3>

            default:
                break;
        }
    }

    if (domNode.name === "h4") {

        switch (layoutSection) {

            case 'CentralisedBlockText':
                return <p className='ou-expanded-article-subtitle'>{domNode.children[0].data}</p>
            case 'AccordionSubDescription':
                return <h4 className='ou-accordion-article-sub-title'>{domNode.children[0].data}</h4>   
                
            default:
                break;
        }
    }

    if (domNode.name === "ul") {

        switch (layoutSection) {
            case 'AboutPriorityUnorderedLists':
                return <dl className='ou-priority-areas-points'>{domToReact(domNode.children, options)}</dl>

            case 'AboutGetInvolved':
                return <div className='ou-group-content'><dl>{domToReact(domNode.children, options)}</dl></div>

               
            case 'CentralisedBlockText':
            case 'ExpandedArticle':
            case 'AccordionSubDescription':
            case 'FiveFacts':
                    return <div className='ou-main-list-items' tabIndex='0'><ul>{domToReact(domNode.children)}</ul></div>;
            case 'AboutTheProgrammeLayout':
                    return <div className='ou-main-list-items'><ul>{domToReact(domNode.children)}</ul></div>;
            default:
                break;
        }
    }


    // Assign the <P> tag class if one allocated.
    if (domNode.name === "p" && paragraphClass.length > 0) {
        return (<p className={paragraphClass}>{domToReact(domNode.children, options)}</p>);
    }
    if (domNode.name === "p" && layoutSection === "InfographicIconsArticleSubtitle") {
        return (<h3 className="ou-expanded-article-subtitle">{domToReact(domNode.children, options)}</h3>);
    }
    if (domNode.name === "p" && layoutSection === "UnorderedListComponent") {
        return (<li>{domToReact(domNode.children, options)}</li>);
    }
    if (domNode.name === "p" && layoutSection === "AncillaryParagraph") {
        return (<p>{domToReact(domNode.children, options)}</p>);
    }
   

    if (domNode.name === "ol") {

        switch (layoutSection) {
               
            case 'CentralisedBlockText':
            case 'ExpandedArticle':
            case 'AccordionSubDescription':
            case 'FiveFacts':
                    return <div className='ou-main-list-items' tabIndex='0'><ol>{domToReact(domNode.children)}</ol></div>;
            default:
                break;
        }
    }

}

