import React from "react";
import { IsEmpty, SetVideoTitle } from '../../helpers/HelperFunctions.js'
import Button from "../../components/Button.js";

export default function Video(props) {
    
    if (IsEmpty(props.video_iframe) || IsEmpty(props.divClassName)) {
      return null  
    } else {
    
    // has to be done this way to add the 2 divs into dangerouslySetInnerHTML.
    const videoDuration = (!IsEmpty(props.video_duration)) ? props.video_duration :  '';

    // if the videoDuration is empty, then the iframe overlay div isn't needed.
    const iframeContainerContent = !IsEmpty(videoDuration) ? SetVideoTitle(props.video_iframe, props.video_title) + "<div class='ou-programme-article-iframe-overlay'><span>"+ videoDuration +"</span><i class='fa fa-play'></i></div>" : SetVideoTitle(props.video_iframe, props.video_title)
    //***** */

    var transcriptAriaLabel = "Read transcript";
    if (IsEmpty(props.video_transcript[0]) || IsEmpty(props.video_transcript[0].description))
    {
        transcriptAriaLabel = "Read transcript";
    }
    else 
    {
        transcriptAriaLabel = props.video_transcript[0].description;
    }  

    return ( 
               
            <div className={props.divClassName} >
                <div className="ou-article-video-wrapper">
                    <div className="ou-programme-article-iframe-container" dangerouslySetInnerHTML={{__html:  iframeContainerContent }} />
                </div>
                <div className="ou-video-playlist-content" >
                    <div className="ou-video-title-container" >
                        <span className="ou-video-index">{props.video_index}. </span>
                        <h4 className="ou-video-title">{props.video_title}</h4>
                    </div>
                    <p className="ou-video-description">{props.video_Description}</p>
                    
                    {!IsEmpty(props.video_transcript) &&
                        <Button 
                            link={props.video_transcript[0].url}
                            text={[<i key={"tile-button-" + props.video_transcript.id} className='fa fa-bars' aria-hidden='true'></i>, "Transcript"]}
                            class="ou-link--primary ou-link--transcript"
                            target="_blank"
                            linkType="External"
                            label={transcriptAriaLabel}
                        />                   
                    }
                </div>
            </div>
       
        )
    }
}