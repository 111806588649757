import React from "react"
import { IsEmpty } from "../../helpers/HelperFunctions.js";
import RenderMedia from "../AssetManagement/RenderMedia.js";


export default function LayoutInfographicIconsArticle(props) {
   
    if (IsEmpty(props.title) || IsEmpty(props.description))
        return null;
    

    return (

        <>
            <div className="ou-expanded-article-icon-card">
                
                {!IsEmpty(props.mediaAssets) &&
                <div className="ou-expanded-article-icon-image-container">
                    <RenderMedia 
                        mediaArray={props.mediaAssets} 
                        showCaptions="false"
                        imageClassName="ou-expanded-article-icon"
                        objectFitSetting="contain"
                    />
                </div>
                }

                {!IsEmpty(props.title) &&
                    <h4 className="ou-expanded-article-icon-title">{props.title}</h4>
                }
                

                {!IsEmpty(props.description) &&
                    <p className="ou-expanded-article-icon-description">{props.description}</p>
                }
                

            </div>
        </>

    )
}


