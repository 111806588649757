import React, { useEffect } from "react"
import $ from 'jquery'
import { IsEmpty } from "../../helpers/HelperFunctions"
import Image from "../AssetManagement/Image.js"

export default function LayoutCarousel(props) {

    useEffect(() => {

          /*Image Slider on load*/
        function ImageSliderOnLoad(imageSliderId) {
            var imageSlider = $("#" + imageSliderId);
            var imageSlides = $(imageSlider).find('.ou-slide');
            var totalSlides = $(imageSlides).length;

            $(imageSlider).attr('data-total-slides', totalSlides);
            $(imageSlider).siblings('.ou-slide-carousel-pagination-container').find('.ou-slide-carousel-pagination-item:eq(0)').addClass('ou-slide-active-pagination').attr({ "tabindex": 0, "aria-selected": true });
            $(imageSlides).eq(0).attr({ "tabindex": 0, "aria-hidden": false });
        }
        function CheckImageSliderNav(imageSliderId) {
            var imageSlider = $("#" + imageSliderId);
            var totalSlides = parseInt($(imageSlider).attr('data-total-slides'));
            var currentSlide = parseInt($(imageSlider).attr('data-current-slide'));
            var leftNav = $(imageSlider).find('.ou-image-slider-nav--left');
            var rightNav = $(imageSlider).find('.ou-image-slider-nav--right');

            if (currentSlide >= totalSlides) {
                $(rightNav).attr('aria-disabled', 'true').addClass('ou-nav-inactive');
                if (totalSlides === 1) {
                    $(leftNav).attr('aria-disabled', 'true').addClass('ou-nav-inactive');
                }
                else {
                    $(leftNav).attr('aria-disabled', 'false').removeClass('ou-nav-inactive').addClass('ou-nav-active');
                }
            }
            else if (currentSlide <= 1) {
                $(leftNav).attr('aria-disabled', 'true').addClass('ou-nav-inactive');
                $(rightNav).attr('aria-disabled', 'false').removeClass('ou-nav-inactive').addClass('ou-nav-active');
            }
            else {
                $(leftNav).attr('aria-disabled', 'false').removeClass('ou-nav-inactive').addClass('ou-nav-active');
                $(rightNav).attr('aria-disabled', 'false').removeClass('ou-nav-inactive').addClass('ou-nav-active');
            }

        }

        function AdjustImageSliderWidth(imageSliderId) {
            var imageSlider = $("#" + imageSliderId);
            $(imageSlider).css('width', 'auto');
            var imageSliderWidth = Math.floor($(imageSlider).outerWidth(true));
        
            if ($(imageSlider).parents('.ou-accordion-content').length) {
                if ($(imageSlider).parents('.ou-accordion-content').is(':visible')) {
                    $(imageSlider).outerWidth(imageSliderWidth, true);
                }
            }
            else {
                $(imageSlider).outerWidth(imageSliderWidth, true);
            }
        
        }

        function SetImageSliderNavLabels(imageSliderId) {
            var imageSlider = $("#" + imageSliderId);
            var totalSlides = parseInt($(imageSlider).attr('data-total-slides'));
            var currentSlide = parseInt($(imageSlider).attr('data-current-slide'));
            var leftNav = $(imageSlider).find('.ou-image-slider-nav--left');
            var rightNav = $(imageSlider).find('.ou-image-slider-nav--right');

            if (currentSlide > 1) {
                var leftNavLabel = "Previous slide - " + (currentSlide - 1) + " of " + totalSlides;
                $(leftNav).attr('aria-label', leftNavLabel);
            }
            else {
                $(leftNav).attr('aria-label', "Previous slide");
            }
            if (currentSlide < totalSlides) {
                var rightNavLabel = "Next slide - " + (currentSlide + 1) + " of " + totalSlides;
                $(rightNav).attr('aria-label', rightNavLabel);
            }
            else {
                $(rightNav).attr('aria-label', "Next slide");
            }
        }

        /*Image Slider*/
        $('.ou-image-slider-nav--right').on('click', function (e) {
            if (($(this).attr('aria-disabled') === 'false') && ($(this).attr('data-triggered') === 'false')) {
                var $this = $(this);
                $this.attr('data-triggered', 'true');
                var imageCarouselWrapper = $this.closest('.ou-slide-carousel-wrapper');
                var imageCarouselId = $(imageCarouselWrapper).attr('id');
                var imageCarousel = $(imageCarouselWrapper).find('.ou-slide-carousel');
                var imageSlides = $(imageCarousel).find('.ou-slide');
                var currentSlideIndex = $(imageCarouselWrapper).siblings('.ou-slide-carousel-pagination-container').find('.ou-slide-active-pagination').index();
                var slideWidth = $(imageCarousel).find('.ou-slide').outerWidth(true);
                var currentSlide = parseInt($(imageCarouselWrapper).attr('data-current-slide'));

                $(imageCarouselWrapper).attr('data-current-slide', currentSlide + 1);
                $(imageCarouselWrapper).siblings('.ou-slide-carousel-pagination-container').find('.ou-slide-active-pagination:eq(0)').removeClass('ou-slide-active-pagination').attr({ "tabindex": -1, "aria-selected": false }).next().addClass('ou-slide-active-pagination').attr({ "tabindex": 0, "aria-selected": true });
                $(imageCarousel).animate({ scrollLeft: '+=' + slideWidth }, 600, function () {
                    CheckImageSliderNav(imageCarouselId);
                    $this.attr('data-triggered', 'false');
                });
                $(imageSlides).eq(currentSlideIndex).attr({ "tabindex": -1, "aria-hidden": true }).next().attr({ "tabindex": 0, "aria-hidden": false });

                SetImageSliderNavLabels(imageCarouselId);

            }
            e.stopImmediatePropagation();
        });
        $('.ou-image-slider-nav--left').on('click', function (e) {
            if (($(this).attr('aria-disabled') === 'false') && ($(this).attr('data-triggered') === 'false')) {
                var $this = $(this);
                $this.attr('data-triggered', 'true');
                var imageCarouselWrapper = $this.closest('.ou-slide-carousel-wrapper');
                var imageCarouselId = $(imageCarouselWrapper).attr('id');
                var imageCarousel = $(imageCarouselWrapper).find('.ou-slide-carousel');
                var imageSlides = $(imageCarousel).find('.ou-slide');
                var currentSlideIndex = $(imageCarouselWrapper).siblings('.ou-slide-carousel-pagination-container').find('.ou-slide-active-pagination').index();
                var slideWidth = $(imageCarousel).find('.ou-slide').outerWidth(true);
                var currentSlide = parseInt($(imageCarouselWrapper).attr('data-current-slide'));

                $(imageCarouselWrapper).attr('data-current-slide', currentSlide - 1);
                $(imageCarouselWrapper).siblings('.ou-slide-carousel-pagination-container').find('.ou-slide-active-pagination:eq(0)').removeClass('ou-slide-active-pagination').attr({ "tabindex": -1, "aria-selected": false }).prev().addClass('ou-slide-active-pagination').attr({ "tabindex": 0, "aria-selected": true });
                $(imageCarousel).animate({ scrollLeft: '-=' + slideWidth }, 600, function () {
                    CheckImageSliderNav(imageCarouselId);
                    $this.attr('data-triggered', 'false');
                });
                $(imageSlides).eq(currentSlideIndex).attr({ "tabindex": -1, "aria-hidden": true }).prev().attr({ "tabindex": 0, "aria-hidden": false });

                SetImageSliderNavLabels(imageCarouselId);
            }
            e.stopImmediatePropagation();
        });

        $('.ou-slide-carousel-pagination-item').on('click', function (e) {
            var currentSlide = $(this).siblings('.ou-slide-active-pagination');
            var currentSlideIndex = $(currentSlide).index();
            var clickedSlideIndex = $(this).index();
            var imageCarouselWrapper = $(this).closest('.ou-slide-carousel-pagination-container').siblings('.ou-slide-carousel-wrapper');
            var imageCarouselId = $(imageCarouselWrapper).attr('id');
            var imageCarousel = $(imageCarouselWrapper).find('.ou-slide-carousel');
            var imageSlides = $(imageCarousel).find('.ou-slide');
    
            $(currentSlide).removeClass('ou-slide-active-pagination').attr({ "tabindex": -1, "aria-selected": false });
            $(this).addClass('ou-slide-active-pagination').attr({ "tabindex": 0, "aria-selected": true });
            $(imageCarouselWrapper).attr('data-current-slide', clickedSlideIndex + 1);
    
    
            var scrollTo = $(imageCarousel).find('.ou-slide').eq(clickedSlideIndex).position().left;
    
            if (window.matchMedia('(max-width:1280px)').matches) {
                $(imageCarousel).animate({ scrollLeft: '+=' + (scrollTo - 32) }, 0, function () { CheckImageSliderNav(imageCarouselId); });
            }
            else {
                $(imageCarousel).animate({ scrollLeft: '+=' + (scrollTo) }, 0, function () { CheckImageSliderNav(imageCarouselId); });
            }
    
            $(imageSlides).eq(currentSlideIndex).attr({ "tabindex": -1, "aria-hidden": true });
            $(imageSlides).eq(clickedSlideIndex).attr({ "tabindex": 0, "aria-hidden": false });
    
            SetImageSliderNavLabels(imageCarouselId);
    
            e.stopImmediatePropagation();
    
        });
    
        $.each($('.ou-slide-carousel-wrapper'), function (ind, imageSlider) {
            var imageSliderId = $(imageSlider).attr('id');
            ImageSliderOnLoad(imageSliderId);
            CheckImageSliderNav(imageSliderId);
            SetImageSliderNavLabels(imageSliderId);
            AdjustImageSliderWidth(imageSliderId);
        });    

        /*Image slider keyboard navigation*/
        $('.ou-slide-carousel-pagination-item').on('keydown', function (e) {
            var $keyCode = e.which;
            if ($keyCode === 38 || $keyCode === 37) {
                e.preventDefault();
                var leftNav = $(this).closest('.ou-slide-carousel-pagination-container').siblings('.ou-slide-carousel-wrapper').find('.ou-image-slider-nav--left');
                if (($(leftNav).attr('aria-disabled') === 'false') && ($(leftNav).attr('data-triggered') === 'false')) {
                    $(leftNav).trigger('click');
                    $(this).prev().focus();
                }
            }
            else if ($keyCode === 40 || $keyCode === 39) {
                e.preventDefault();
                var rightNav = $(this).closest('.ou-slide-carousel-pagination-container').siblings('.ou-slide-carousel-wrapper').find('.ou-image-slider-nav--right');
                if (($(rightNav).attr('aria-disabled') === 'false') && ($(rightNav).attr('data-triggered') === 'false')) {
                    $(rightNav).trigger('click');
                    $(this).next().focus();
                }
            }
            e.stopImmediatePropagation();
        });

        /*Image Slider on window resize*/
        $(window).resize(function () {
            $.each($('.ou-slide-carousel-wrapper'), function (ind, imageSlider) {
                var imageSliderId = $(imageSlider).attr('id');
                AdjustImageSliderWidth(imageSliderId);

                $(imageSlider).siblings('.ou-slide-carousel-pagination-container').find('.ou-slide-active-pagination').trigger('click');
            });
        }); 

   

    });
       
    if (IsEmpty(props.listOfCarouselItems))
        return null;

    return (
        <>
        <React.Fragment key={props.keyProp}></React.Fragment>
            <section className="ou-image-slider-wrapper">

                {!IsEmpty(props.carouselTitle) &&
                    <div className="ou-slide-carousel-header">
                        <h3>{props.carouselTitle.value}</h3>
                    </div>
                }

                <div className="ou-slide-carousel-wrapper" id={"imageSlider-" + props.articleIndex} data-current-slide="1" data-total-slides="1">
                    <button className="ou-slider-nav ou-image-slider-nav--left" aria-label="Previous Slide" aria-disabled="false" data-triggered="false"><i className="fa fa-chevron-left"></i></button>

                    <div className="ou-slide-carousel-container">
                        <div className="ou-slide-carousel">

                            {props.listOfCarouselItems.value.map(({ elements }, i) => (
                                <React.Fragment key={"div-" + props.listOfCarouselItems.value[i].id + "-" + props.articleIndex + "-" + i}>
                                    <div className="ou-slide" role="tabpanel" id={"imageSlider-" + props.articleIndex + "-tabpanel" + (i + 1)} tabIndex="-1" aria-hidden="true" aria-labelledby={"imageSlider-" + props.articleIndex + "-slideTab" + (i + 1)}>
                                        <div className="ou-slide-image">
                                            <Image 
                                                image={elements.carousel_image.value}
                                            />
                                        </div>

                                        <div className="ou-slide-content" dangerouslySetInnerHTML={{__html: elements.carousel_description.value}} />
                                    </div> 
                                </React.Fragment>                                   
                            ))} 

                        </div>
                    </div>
                    <button className="ou-slider-nav ou-image-slider-nav--right" aria-label="Next Slide" aria-disabled="false" data-triggered="false"><i className="fa fa-chevron-right"></i></button>
                </div>
                <div className="ou-slide-carousel-pagination-container">
                    <div className="ou-slide-carousel-pagination" role="tablist">
                        {props.listOfCarouselItems.value.map(({ elements }, i) => (
                            <React.Fragment key={"btn-" + props.listOfCarouselItems.value[i].id + "-" + props.articleIndex + "-" + i}>
                                <button className="ou-slide-carousel-pagination-item" role="tab" id={"imageSlider-" + props.articleIndex + "-slideTab" + (i + 1)} aria-label={"Image slide " + (i + 1)} tabIndex="-1" aria-selected="false" aria-controls={"imageSlider-" + props.articleIndex + "-tabpanel" + (i + 1)}></button>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </section>
                        
        </>     
    )

}

