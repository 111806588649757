import React from "react"
import LayoutQuiz from "./LayoutQuiz.js"

export default function LayoutExpandedArticleQuiz(props) {
   
    const oddEven = (props.oddEvenDisplay === "Black") ? "ou-expanded-article-container article-odd" : "ou-expanded-article-container article-even";

    return (

        <>
            <div className={oddEven}>

                <LayoutQuiz
                    iFrameLink={props.richText.elements.quiz_iframe_link}
                    title={props.richText.elements.title}
                    body={props.richText.elements.body}
                />

            </div>

        </>

    )
}