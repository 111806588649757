import React from "react"
import { IsEmpty, SetVideoTitle } from "../../helpers/HelperFunctions"
import ModularContentProcessor from "../StructuredContent/ModularContentProcessor.js";
import VideoPlaylist from "../VideoPlaylist/VideoPlaylist.js"
import { StaticImage} from "gatsby-plugin-image"

export default function LayoutVideoPlaylist(props) {

    
       
    if (IsEmpty(props.listOfVideos) && IsEmpty(props.playlistTitle) && IsEmpty(props.playlistBody))
        return null;

    return (
        <>
        <div className="ou-video-playlist-banner-wrapper">
            <div className="ou-video-playlist-banner">
                <div className="ou-article-video-wrapper">
                    <div className="ou-programme-article-iframe-container" tabIndex="0" dangerouslySetInnerHTML={{__html: SetVideoTitle(props.listOfVideos.value[0].elements.external_iframe_link.value, props.listOfVideos.value[0].elements.video_title.value)}} />
                </div>
                <div className="ou-video-playlist-banner-content-container">
                    <div className="ou-video-playlist-details">
                        <div className="ou-video-playlist-count">
                            <div className="ou-video-playlist-icon">
                                <StaticImage src="../../images/Video_playlist.svg" alt="video playlist"/>
                            </div>
                            <span className="videoCount"></span>
                        </div>
                        {!IsEmpty(props.duration_summary.value)  &&
                            <div className="ou-video-duration">
                                <span>{props.duration_summary.value}</span>
                                <i className="fa fa-play" aria-hidden="true"></i>
                            </div>
                        }
                    </div>
             
                    <div className="ou-video-playlist-banner-content">
                        <h3 className="ou-video-playlist-banner-header">{props.playlistTitle.value}</h3>
                        <ModularContentProcessor 
                            richText={props.playlistBody}
                            layoutSection={"videoListParagraph"}
                        />
                        <a href="#" className="ou-link--primary ou-link--video-playlist" data-modal={"#videoPlaylist" + props.overlayID} data-modal-overlay={"#videoPlaylistOverlay" + props.overlayID}><i className="fa fa-play" aria-hidden="true"></i>Play All</a>
                    </div>
                </div>
            </div>
        </div>
        
        <VideoPlaylist 
            listOfVideos={props.listOfVideos}
            playlistTitle={props.playlistTitle.value}
            overlayID={props.overlayID}
        />



       </>     
    )

}