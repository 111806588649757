import React from "react";
import { IsEmpty } from '../../helpers/HelperFunctions.js'

export default function ImageCaption(props) {
    
    if (IsEmpty(props.ImageCaption)) {
      return null  
    } else {
            
    return ( 
        <div className="ou-image-caption-container">
            <span dangerouslySetInnerHTML={{__html: props.ImageCaption}} />
        </div>
        )
    }
}