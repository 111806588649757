import ParseHTMLString from './ParseHTMLString';


export function IsEmpty(value)
{
    return value === undefined || value === null || value.length === 0 || value === '<p><br></p>'; 
}

export function IsEmptyArray(contentItemWithArray)
{
    return (!(contentItemWithArray && Array.isArray(contentItemWithArray.value) && contentItemWithArray.value.length > 0));
}

// Loop through subject areas and find the sub-category that matches the value we're passing in 
// e.g. If we pass in "Nature" as the sub-category, find which subject area this belongs to and return the subject area title
export function FindSubjectAreaForCategory(subjectAreas, subCategory)
{
    var subjectAreaTitle = "";

    subjectAreas.forEach(node => {
        node["node"].elements["sub_categories_list"].value.forEach(category => {
            if (category.elements.category_name.value === subCategory)
            {
                subjectAreaTitle = node["node"].elements["title"].value;

                return subjectAreaTitle;
            }
        });
    });

    return subjectAreaTitle;
}

// Takes input html and removes tags e.g. "<p>This is a test</p>" returns "This is a test"
export function FindHtmlInnerText(inputHtml)
{
    const regex = /(<[^>]+>)/g;
    var innerText = inputHtml.replace(regex, "");

    return innerText;
}

export function SetVideoTitle(iFrameText, videoTitle)
{
    // Video title has not been set up in Kontent, so just return original iFrame content
    if (IsEmpty(videoTitle))
    {
        return iFrameText;
    }

    // Convert the iFrame text to an html element
    var parser = new DOMParser();
    var doc = parser.parseFromString(iFrameText, 'text/html'); 

    // Just double check that iframe tag exists (it should!)
    if (doc.body.getElementsByTagName('iframe')[0] !== undefined)
    {
        // Add/Update from the title field passed in
        doc.body.getElementsByTagName('iframe')[0].setAttribute("title", videoTitle);

        return doc.body.innerHTML;
    }

    return iFrameText;
}

// Is this a title, text and image layout?
export function IsTitleTextImage(layoutType)
{
    if (layoutType === "layout_title_text_image")
    {
        return true;
    }

    return false;
}

// Is this a quiz layout?
export function IsQuizLayout(layoutType)
{
    if (layoutType === "layout___quiz")
    {
        return true;
    }
    
    return false;
}

// Is this a video playlist layout?
export function IsVideoPlaylistLayout(layoutType)
{
    if (layoutType === "layout___video_playlist")
    {
        return true;
    }
    
    return false;
}

// Is this a video playlist layout?
export function IsCarouselLayout(layoutType)
{
    if (layoutType === "layout___carousel")
    {
        return true;
    }
    
    return false;
}

export function IsCurrentDateBetweenDates(StartDate, EndDate)
{
    // for information on Moment - https://momentjs.com/docs/
    var moment = require('moment');
    var currentDate = new Date();   
    if (moment(currentDate).isBetween(StartDate, EndDate, 'minute', []))
    {
        return true;
    }
    
    return false;
}

// Construct programme page url. If programme page url does not contain '/' then we get the path created in gatsby-node.js
export function GenerateProgrammePageUrl(allPages, programmePageUrl)
{
    let generatedProgrammePageUrl = programmePageUrl;

    if (IsEmpty(allPages))
        return generatedProgrammePageUrl;

    // We only need to get the generated path (performed in gatsby-node.js) if the programme page url does not contain a / character
    if (programmePageUrl.indexOf("/") < 0)
    {

       /*allPages.nodes.forEach(node => {
            // Console.log the slug name if slug contains the word 'linford'.
            if (node.pageContext.slug?.includes("linford")) {
                console.log("ppURL="+programmePageUrl);
                console.log(node.pageContext.slug === programmePageUrl);
                console.log("path="+node.path);
                console.log(node);
            }
        } );
        */

        // Filter the array (matching slug on programme page url passed in) and get the path name for that record
        const programmePagePath = allPages.nodes.filter(node => node.pageContext.slug === programmePageUrl)[0].path;

        if (!IsEmpty(programmePagePath))
        {
            generatedProgrammePageUrl = programmePagePath;
        }
    }

    return generatedProgrammePageUrl;
}


export function formatDateMonthYear(dateToFormat)
{
    // for information on Moment - https://momentjs.com/docs/
    var moment = require('moment');
       
    dateToFormat = moment(dateToFormat).format('MMMM yyyy');

    return dateToFormat;
}


export function removeParagraphTags(textString)
{
    if (!IsEmpty(textString))
    {
        textString = textString.replace(/(<([^>]+)>)/ig, "");
    }

    return textString;
}

export function cleanParagraphs(textString)
{
    if (!IsEmpty(textString))
    {
        textString = textString.replace(/<\/?[^>]+(>|$)/g, "");
        textString = textString.replace(/&nbsp;/g, " ");
        textString = textString.replace(/&amp;/g, "&");
        textString = textString.replace(/&quot;/g, "\"");
        textString = textString.replace(/&apos;/g, "'");
        textString = textString.replace(/&lt;/g, "<");
        textString = textString.replace(/&gt;/g, ">");
        textString = textString.replace(/&ldquo;/g, "\"");
        textString = textString.replace(/&rdquo;/g, "\"");  
        textString = textString.replace(/&rsquo;/g, "'");
        textString = textString.replace(/&lsquo;/g, "'");   
        textString = textString.replace(/&mdash;/g, "-");
        textString = textString.replace(/&ndash;/g, "-");   
        textString = textString.replace(/&hellip;/g, "...");
        textString = textString.replace(".", ". ");
    }

    return textString;
}