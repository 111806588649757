import React from "react"
import { IsEmpty, IsEmptyArray } from "../../helpers/HelperFunctions.js";
import DisplayChannelLogo from "../DisplayChannelLogo/DisplayChannelLogo";


export default function LayoutRecentProjects(props) {
   
    if (IsEmpty(props.broadcastChannel) || IsEmpty(props.listOfProgrammes))
        return null;
    

    return (

        <div className="ou-bbc-channel-container">

            {!IsEmptyArray(props.broadcastChannel) &&

                <DisplayChannelLogo 
                    channelID={props.broadcastChannel.value[0].name}
                    width={158}
                     />

            }

            {!IsEmptyArray(props.listOfProgrammes) &&
            <div>
                <dl className="ou-bbc-channel-points">
                    {props.listOfProgrammes.value.map(({ elements }) => (
                        <React.Fragment key={elements.programme_to_display.value[0].id}>
                        <dt>-&nbsp;
                            {/*<Link 
                                to={elements.url.value}
                            >*/}
                            {elements.programme_to_display.value[0].elements.title.value}
                            {/*</Link>*/}
                        </dt>
                        </React.Fragment>
                    ))} 
                </dl>
            </div>
            }
        </div>

    )
}


