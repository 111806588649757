import React, {useEffect, useState} from "react"
//import { useStorageState } from "react-storage-hooks";
import { StaticQuery, graphql } from "gatsby"
import ModularContentProcessor from "../StructuredContent/ModularContentProcessor.js";
import { IsEmpty, IsCurrentDateBetweenDates} from '../../helpers/HelperFunctions.js'
import Image from "../AssetManagement/Image.js"

export const PureSiteWideMessage = ({ data }) => (
  <>
  {data.allKontentItemWebItemSiteMessage.edges.map(({ node }) => (
  
      <SiteWideMessageContent 
        Title={node.elements.site_message_title.value}
        Subtitle={node.elements.site_message_subtitle.value}
        MessageBody={node.elements.site_message_body}
        StartDate={node.elements.site_message_start_date.value}
        EndDate={node.elements.site_message_end_date.value}
        Activate={node.elements.site_message_activate_message.value[0].name}
        SiteMessageImage={node.elements.site_message_image.value}
        ButtonText={node.elements.site_message_button_text.value}
        ButtonLink={node.elements.site_message_button_link.value}
        key={node.id}
      />

    ))}

</>

) 


// Default exported component with Static Query - utilises 'pure' version above for rendering.
export const SiteWideMessage = props => (
  <StaticQuery 
        query = {graphql `query {
          allKontentItemWebItemSiteMessage(
            filter: {elements: {site_message_activate_message: {value: {elemMatch: {name: {eq: "Activate"}}}}}}
            limit: 1
          ) {
            edges {
              node {
                elements {
                  site_message_activate_message {
                    value {
                      name
                    }
                  }
                  site_message_body {
                    images {
                      description
                      height
                      image_id
                      url
                      width
                      }
                      links {
                    codename
                    link_id
                    type
                      url_slug
                      }
                      value 
                      modular_content {
                        id
                      }
                  }
                  site_message_start_date {
                    value
                  }
                  site_message_end_date {
                    value
                  }
                  site_message_image {
                    value {
                      ...AssetFragment
                    }
                  }
                  site_message_title {
                    value
                  }
                  site_message_subtitle {
                    value
                  }
                  site_message_button_text {
                    value
                  }
                  site_message_button_link {
                    value
                  }
                }
                id
              }
            }
          }
          }`}

        render = { data => <PureSiteWideMessage props={props} data={data} />}
    />
)


export default SiteWideMessage



export const SiteWideMessageContent = ({Title, Subtitle, MessageBody, StartDate, EndDate, Activate, SiteMessageImage, ButtonText, ButtonLink}) => {

//const [show, setShow] = useStorageState(sessionStorage, 'state-show-message', ShowSiteMessage(Activate, StartDate, EndDate));
const [show, setShow] = useState(false);

useEffect(() => {
  const hasShownMessage = sessionStorage.getItem('state-show-message');
  if (!hasShownMessage && ShowSiteMessage(Activate, StartDate, EndDate)) {
    setShow(true);
    sessionStorage.setItem('state-show-message', 'true');
  }
}, [Activate, StartDate, EndDate]);


const sectionClass = IsEmpty(SiteMessageImage) ? "ou-site-message-modal-wrapper ou-site-message--text-only" : "ou-site-message-modal-wrapper";

 if (!show)
      return null

  return (
        
    <>
        <section className={sectionClass} tabIndex="0" role="alertdialog" aria-labelledby="siteMessageTitle1 siteMessageTitle2" aria-describedby="siteMessage1">
        <div className="ou-site-message-modal-container">
            <button className="ou-modal-close-button ou-site-message-close" aria-label="Close alert dialog" onClick={() => setShow(false)}></button>
            <div className="ou-site-message-modal-body">
                <div className="ou-site-message-modal-content-container">
                    <div className="ou-site-message-modal-content">
                        <h2 className="ou-site-message-title--level1" id="siteMessageTitle1">{Title}</h2>
                        {!IsEmpty(Subtitle) &&
                            <h3 className="ou-site-message-title--level2" id="siteMessageTitle2">{Subtitle}</h3>
                        }
                        <ModularContentProcessor
                            richText={MessageBody}
                            layoutSection="SiteWideMessage"
                        />

                        {/* if button text and no link, just desplay the button */}
                        {(!IsEmpty(ButtonText) && IsEmpty(ButtonLink))  &&
                              //this is a button to avoid getting a /# added to the URL
                            <button className="ou-link--primary ou-site-message-close" aria-label={ButtonText} onClick={() => setShow(false)}>{ButtonText}<i className="fa fa-chevron-right" ></i></button>
                        }
                        {/* if button text AND link text have a value, then display the button and add the href */}
                        {(!IsEmpty(ButtonText) && !IsEmpty(ButtonLink)) &&
                            <a href={ButtonLink} aria-label={ButtonText} className="ou-link--primary" onClick={() => setShow(false)}>{ButtonText}<i className="fa fa-chevron-right" ></i></a>
                        }
                        

                    </div>
                </div>
                {!IsEmpty(SiteMessageImage)  &&     
                  <div className="ou-site-message-image">
                    <Image 
                        image={SiteMessageImage}
                    /> 
                  </div>
                }
            </div>
        </div>
    </section>
    <div className="ou-site-message-modal-overlay ou-site-message-close" onClick={() => setShow(false)}></div>
    </>
    )
}

export function ShowSiteMessage(Activate, StartDate, EndDate)
{
    // is the message active?
    if (Activate === "Activate")
    {
      // if active, check dates have a value
     if(!IsEmpty(StartDate) && !IsEmpty(EndDate))
     {
        // if there are dates, check that the current date is between the start and end dates
        if (IsCurrentDateBetweenDates(StartDate, EndDate))
        {
          // if so, return true
          return true;
        }
      }
      else
      // if active and no dates, return true
      return true;
    }
    return false;
}