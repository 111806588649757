import React from "react"
import ModularContentProcessor from "../StructuredContent/ModularContentProcessor.js";
import RenderMedia from "../AssetManagement/RenderMedia";
import { IsEmptyArray } from "../../helpers/HelperFunctions"

export default function LayoutFiveFacts(props) {
   
    const oddEven = (props.oddEvenDisplay === "Black") ? "ou-programme-article-wrapper article-odd" : "ou-programme-article-wrapper article-even";
    const layoutSection = "FiveFacts";

    
    return (

        <section className={oddEven}>
            <div className="ou-programme-article-container">
                {!IsEmptyArray(props.mediaAssets) &&
                    <div className="ou-programme-article-image-container">
                        {/* Display media assets.. May have a combination of iFrameLink, image or both. */}
                        <RenderMedia 
                            mediaArray={props.mediaAssets} 
                            showCaptions="true"
                            imageClassName="ou-programme-article-image"
                            captionsInnerContainerClassName="ou-programme-article-image-inner-container"
                            imageCaptionClassName="ou-programme-article-image-subtext"/>
                    </div>
                }

                <div className="ou-programme-article-content">
                    <h3 className="ou-programme-article-title">{props.title}</h3>

                    <ModularContentProcessor 
                        richText={props.richText}
                        layoutSection={layoutSection}
                    />
                </div>

            </div>
        </section>

    )
}


