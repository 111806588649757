import React, { useEffect } from "react"
import Footer from "../footer/Footer"
import Header from "../header/Header"
import { Helmet } from "react-helmet"
import "../styles/ou-bbc-local.css"
import "../styles/ou-bbc-main.css"
import "../styles/cassiecookiewidget2.css"
import $ from 'jquery'
import "../SiteWideMessage/SiteWideMessage.js"
import SiteWideMessage from "../SiteWideMessage/SiteWideMessage.js"


export default function Layout({ children }) {

    const searchBoxEngine = "https://cse.google.com/cse.js?cx=008fc33a18c3f41ba&key=AIzaSyAoDBjZEvux6STERIENurGefwtdcCwZc3w";
    const hasMounted = useHasMounted(); 

    useEffect(() => {
        if (hasMounted) {
                if (document.location.href.indexOf("localhost") < 0)
                {
                    const script = document.createElement("script");
                    script.src = process.env.GATSBY_COOKIE_MANAGEMENT_URL;
    
                    script.addEventListener('load', function() {
    
                        const loadWidget = document.createElement("script");
                        loadWidget.append("var cassieSettings = { " + process.env.GATSBY_COOKIE_MANAGEMENT_PARAMETERS + " };  window.CassieWidgetLoader = new CassieWidgetLoaderModule(cassieSettings);");
                        document.head.appendChild(loadWidget); 
    
                    });     
                    document.head.appendChild(script);
                }

            }

            // Cookie banner event listeners
            $(document).on('CassieTemplateInitialized', function () {
        
                function focusSiteMessageModal() {
                    var $modal = $('.ou-site-message-modal-wrapper');
                    if ($modal.length) {
                        $modal.focus();
                    }
                }
                 
                if (window.CassieWidgetLoader !== undefined && !window.CassieWidgetLoader.Widget.hasConsent()) {
                    $(document).on('CassieSubmittedConsent', focusSiteMessageModal);
                }
                else {
                    focusSiteMessageModal();
                }
                
                $('.cassie-pre-banner--text').find('a').first().on('keydown', function (e) {
                    if (e.which === 9 && e.shiftKey) {
                        e.preventDefault();
                        $('.cassie-pre-banner--button.cassie-view-all').focus();
                    }
                });
            
                $('.cassie-pre-banner--button.cassie-view-all').on('keydown', function (e) {
                    if (e.which === 9 && !e.shiftKey) {
                        e.preventDefault();
                        $('.cassie-pre-banner--text').find('a').first().focus();
                    }
                });
            
                $('.cassie-cookie-modal--footer-extra a, .cassie-cookie-modal--footer-extra button').last().on('keydown', function (e) {
                    if (e.which === 9 && !e.shiftKey) {
                        e.preventDefault();
                        $('#cassie_accept_all_toggle_switch').focus();
                    }
                });
            
                $('#cassie_accept_all_toggle_switch').on('keydown', function (e) {
                    if (e.which === 9 && e.shiftKey) {
                        e.preventDefault();
                        $('.cassie-cookie-modal--footer-extra a, .cassie-cookie-modal--footer-extra button').last().focus();
                    }
                });
        
            });
        }, [hasMounted])    

    useEffect(() => {

        var sitePath = document.location.pathname;

        //Touch device check
        if (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0)) {
            $('body').addClass('touch-enabled');
        }

        //Handle Search Bar position
        var windowType = 0;
        HandleSearchBarPosition(true);
        $(window).on('resize', function () {
            HandleSearchBarPosition(false);
        });

        //Handle Search Bar position
        function HandleSearchBarPosition(onWindowLoad) {
            let searchHeaderRow = $('.ou-bbc-header-container').find('.ou-header-row');
            let secondaryHeaderRow = $('.ou-bbc-header--secondary');
            if (window.matchMedia('(max-width:512px)').matches) {
                if (onWindowLoad || windowType !== 1) {
                    $(secondaryHeaderRow).show().prepend($(searchHeaderRow));
                    windowType = 1;
                }
            }
            else if (window.matchMedia('(min-width:512px) and (max-width:768px)').matches) {
                if (onWindowLoad || windowType !== 2) {
                    let headerLogo = $('.ou-bbc-header-container').find('.ou-bbc-header-logo');
                    $(headerLogo).after($(searchHeaderRow));
                    $(secondaryHeaderRow).hide();
                    windowType = 2;
                }
            }
            else {
                if (onWindowLoad || windowType !== 3) {
                    let mainHeader = $('.ou-header-main');
                    $(mainHeader).prepend($(searchHeaderRow));
                    $(secondaryHeaderRow).hide();
                    windowType = 3;
                }
            }
        }

        $(window).on('pagehide', function () {
            var activeAccordionIndex = GetActiveAccordionIndex();
            var scrollposition = $(window).scrollTop();
            sessionStorage.setItem("activeAccordion" + sitePath, activeAccordionIndex.toString());
            sessionStorage.setItem("scrollPosition" + sitePath, scrollposition.toString());
        });


        /*Toggle nav dropdown menu*/
        $('.ou-menu-dropdown').on('click', function () {
            $(this).find('.ou-bbc-categories-sub-menu').toggle();
            $('> button', this).attr('aria-expanded', function (i, value) {
                return value === 'true' ? 'false' : 'true';
            });            
        });
        $('.ou-bbc-categories-sub-menu').on('click', function (event) {
            event.stopPropagation();
        });

        $(document).on('click', function (event) {
            var navDropdownMenuButton = $('.ou-menu-dropdown');
            var navDropDownMenu = $(navDropdownMenuButton).find('.ou-bbc-categories-sub-menu');
            if (!$(event.target).closest(navDropdownMenuButton).length) {
                navDropDownMenu.hide();
            }
        });
        

        /*Toggle submenu in mobile nav*/
        $('.ou-bbc-sub-menu-element-header').on('click', function () {
            if (window.matchMedia('(max-width:768px)').matches) {
                $(this).next('ul').toggle();
            }
        });

        /*Mobile Nav Toggle*/
        $('.mobile-nav-toggle').off().on('click', function () {
            if ($(this).closest('.ou-mobile-nav-toggle-container').hasClass('ou-mobile-nav-inactive')) {
                $(this).closest('.ou-mobile-nav-toggle-container').removeClass('ou-mobile-nav-inactive').addClass('ou-mobile-nav-active');
                $('.ou-header-main').addClass('mobile-nav-open');
                $(this).removeClass('fa-bars').addClass('fa-times');
            }
            else {
                $(this).closest('.ou-mobile-nav-toggle-container').removeClass('ou-mobile-nav-active').addClass('ou-mobile-nav-inactive');
                $('.ou-header-main').removeClass('mobile-nav-open');
                $(this).removeClass('fa-times').addClass('fa-bars');
            }
        });

        /*Latest Article on hover*/
        $('.ou-latest-article').on('mouseenter mouseleave focusin focusout',function (event) {
            if (window.matchMedia('(min-width:769px)').matches && window.devicePixelRatio <= 2) {
                var articleTitle = $(this).find('.ou-latest-article-title');
                var articleContent = $(this).find('.ou-latest-article-content');
                var toggleTitle = articleTitle.data('toggle-title');
                if (toggleTitle) {
                    articleContent.toggleClass('ou-extend-latest-article-content');
                    articleTitle.data('toggle-title', articleTitle.html());
                    articleTitle.toggleClass('ou-latest-article-toggle-title');
                    articleTitle.html(toggleTitle);
                }
            }
    
        });

        
        /*Partnership Catalogue Carousel*/
        if (window.matchMedia('(max-width:768px)').matches) {
            $('.ou-inner-article').find('.ou-link--primary').html('Read article <i class="fa fa-chevron-right"></i>');
            var carouselArticles = $('.ou-partnership-programs-container').find('.ou-latest-article');
            $('.ou-partnership-programs-catalogue').find('.ou-article-carousel-wrapper').toggle();
            $('.ou-partnership-programs-container').remove();
            $(carouselArticles).each(function (index) {
                $(this).find('.ou-link--primary').html('Read article <i class="fa fa-chevron-right"></i>');
                $('.ou-inner-article-carousel').append($(this).contents().wrapAll('<div class="ou-inner-article"><div class="ou-inner-article-content-wrapper"></div></div>').parent().parent());
            }).promise().done(function () {
                RestoreScrollPosition(sitePath);
            });
        }

        //Get active accordion index
       function GetActiveAccordionIndex() {
            var activeAccordionIndex = -1;
            $('.ou-accordion-articles-wrapper').each(function () {
                var $accordionWrapper = $(this);
                $accordionWrapper.children().each(function () {
                    if ($(this).hasClass('active-article')) {
                        activeAccordionIndex = $accordionWrapper.index('.ou-accordion-articles-wrapper');
                    }
                })
        
            });
            return activeAccordionIndex;
        }

        //Scroll Restoration
        function RestoreScrollPosition(sitePath) {
            if (sessionStorage["scrollPosition" + sitePath]) {
                var scrollPosition = sessionStorage.getItem("scrollPosition" + sitePath);
                $('html,body').scrollTop(scrollPosition);
            }
        }
        function RestoreActiveAccordion(sitePath) {
            if (sessionStorage["activeAccordion" + sitePath]) {
                var activeAccordionIndex = sessionStorage.getItem("activeAccordion" + sitePath);
                if (activeAccordionIndex > -1) {
                    $('.ou-accordion-articles-wrapper').eq(activeAccordionIndex).children().eq(0).find('.ou-accordion-toggle').trigger('click');
                }
            }
        }
        async function RestoreSessionStorage(sitePath) {
            await RestoreActiveAccordion(sitePath);
            setTimeout(RestoreScrollPosition, 1000, sitePath);
        }

        /*Scroll Restoration*/
        if (!document.location.hash) {
            RestoreSessionStorage(sitePath);
        }

		/*Image CC*/
        $('.ou-image-cc-btn').on('click', function (e) {
            $(this).closest('.ou-image-cc-btn-container').siblings('.ou-image-cc-content-container').find('.ou-image-cc-content').toggleClass('ou-image-cc--active');
            e.stopImmediatePropagation();
        });

        //Video Playlist on load
        function GetVideoCount(playlistId) {
            var playlistVideos = $(playlistId).find('.ou-playlist-video');
            $(playlistId).find('.ou-video-playlist-carousel-wrapper').attr('data-video-count', playlistVideos.length);
        }
        
        async function SetVideoCount(playlistId, playlistBanner) {
            await GetVideoCount(playlistId);
            var videoCount = $(playlistId).find('.ou-video-playlist-carousel-wrapper').attr('data-video-count');
            $(playlistId).find('.videoCount').html("x" + videoCount);
            $(playlistBanner).find('.videoCount').html("x" + videoCount);
        }
        
        function VideoPlaylistOnLoad(playlistId) {
            var playlistCarousel = $(playlistId).find('.ou-video-playlist-carousel-wrapper');
            var playlistMainVideo = $(playlistCarousel).find('.ou-video-playlist__video-main');
            $(playlistMainVideo).find('iframe').attr('tabindex', '0');
            $(playlistMainVideo).find('.ou-video-playlist-content').find('a').last().addClass('ou-videoContent-link');
            $(playlistMainVideo).next().css({ left: '140px', transform: 'scale(0.9)', opacity: '0.5', marginTop: '-12px' });
            $(playlistMainVideo).next().next().css({ left: '280px', transform: 'scale(0.8)', opacity: '0.3', marginTop: '-6px' });
        
            var hiddenVideoList = $(playlistMainVideo).next().next().nextAll();
            $.each($(hiddenVideoList), function (index, videoItem) {
                var leftShift = (index + 3) * 140;
                $(videoItem).css({ 'left': leftShift, transform: 'scale(0)', opacity: '0', marginTop: '-6px' });
            });
        
            var currentVideo = $(playlistCarousel).attr('data-current-video');
            $(playlistCarousel).find('.ou-video-playlist-content').eq((currentVideo) - 1).css('opacity', '1');
        }
        
        function CheckVideoPlaylistNav(playlistId) {
            var videoCarousel = $("#" + playlistId).find('.ou-video-playlist-carousel-wrapper');
            var leftNav = $("#" + playlistId).find('.ou-video-playlist--left-nav');
            var rightNav = $("#" + playlistId).find('.ou-video-playlist--right-nav');
            if (parseInt($(videoCarousel).attr('data-current-video')) >= parseInt($(videoCarousel).attr('data-video-count'))) {
                $(rightNav).attr('aria-disabled', 'true').addClass('ou-nav-inactive');
                if (parseInt($(videoCarousel).attr('data-video-count'))===1) {
                    $(leftNav).attr('aria-disabled', 'true').addClass('ou-nav-inactive');
                }
            }
            else if (parseInt($(videoCarousel).attr('data-current-video')) <= 1) {
                $(leftNav).attr('aria-disabled', 'true').addClass('ou-nav-inactive');
            }
            else {
                $(leftNav).attr('aria-disabled', 'false').removeClass('ou-nav-inactive').addClass('ou-nav-active');
                $(rightNav).attr('aria-disabled', 'false').removeClass('ou-nav-inactive').addClass('ou-nav-active');
            }
        }
        function SetVideoPlaylistNavLabels(playlistId) {
            var videoCarousel = $("#" + playlistId).find('.ou-video-playlist-carousel-wrapper');
            var leftNav = $("#" + playlistId).find('.ou-video-playlist--left-nav');
            var rightNav = $("#" + playlistId).find('.ou-video-playlist--right-nav');
            var currentVideo = parseInt($(videoCarousel).attr('data-current-video'));
            var videoCount = parseInt($(videoCarousel).attr('data-video-count'));
            if (currentVideo > 1) {
                var leftNavLabel = "Previous video - " + (currentVideo - 1) + " of " + videoCount;
                $(leftNav).attr('aria-label', leftNavLabel);
            }
            else {
                $(leftNav).attr('aria-label', "Previous video");
            }
            if (currentVideo < videoCount) {
                var rightNavLabel = "Next video - " + (currentVideo + 1) + " of " + videoCount;
                $(rightNav).attr('aria-label', rightNavLabel);
            }
            else {
                $(rightNav).attr('aria-label', "Next video");
            }
        }


        /*Video Playlist*/
        $('.ou-video-playlist--right-nav').on('click', function (e) {
            if (($(this).attr('aria-disabled') === 'false') && ($(this).attr('data-triggered') === 'false')) {
                var $this = $(this);
                $this.attr('data-triggered', 'true');
    
                var mainVideo = $(this).siblings('.ou-video-playlist-carousel-container').find('.ou-video-playlist__video-main');
                var videoCarousel = $(this).closest('.ou-video-playlist-carousel-wrapper');
    
                var iframeElement = $(mainVideo).find('iframe');
                $(iframeElement).attr('src', $(iframeElement).attr('src'));
    
                $(mainVideo).nextAll().animate({ 'left': '-=140px' }, 300);
                $(mainVideo).prevAll().animate({ 'left': '-=140px' }, 300);
                $(mainVideo).next().css({ 'transform': 'scale(1)', 'opacity': '1', 'z-index': '3', 'marginTop': '0px' });
                $(mainVideo).next().next().css({ 'transform': 'scale(0.9)', 'opacity': '0.5', 'z-index': '2', 'marginTop': '-12px' });
                $(mainVideo).next().next().next().css({ 'left': '280px', 'transform': 'scale(0.8)', 'opacity': '0.3', 'z-index': '1', 'marginTop': '-6px' });
                $(mainVideo).css({ 'left': '-=140px', 'transform': 'scale(0.9)', 'opacity': '0.5', 'z-index': '2', 'marginTop': '-12px' });
                $(mainVideo).prev().css({ 'transform': 'scale(0.8)', 'opacity': '0.3', 'z-index': '1', 'marginTop': '-6px' });
                $(mainVideo).prev().prevAll().css({ 'transform': 'scale(0)', 'opacity': '0' });
                $(mainVideo).find('iframe').attr('tabindex', '-1');
                $(mainVideo).next().find('iframe').attr('tabindex', '0');
                $(mainVideo).find('.ou-video-playlist-content').find('a').last().removeClass('ou-videoContent-link');
                $(mainVideo).next().find('.ou-video-playlist-content').find('a').last().addClass('ou-videoContent-link');
                $(mainVideo).removeClass('ou-video-playlist__video-main').next().addClass('ou-video-playlist__video-main');
    
                var currentVideo = parseInt($(videoCarousel).attr('data-current-video'));
                var videoContent = $(videoCarousel).find('.ou-video-playlist-content');
                $(videoContent).eq((currentVideo) - 1).css('opacity', '0');
                $(videoContent).eq((currentVideo)).animate({ 'opacity': 1 }, 750, function () {
                    $this.attr('data-triggered', 'false');
                });
                $(videoCarousel).attr('data-current-video', currentVideo + 1);
    
                $(mainVideo).next().find('iframe').focus();
    
                var playlistId = $(this).closest('.ou-video-playlist-modal-wrapper').attr('id');
                CheckVideoPlaylistNav(playlistId);
                SetVideoPlaylistNavLabels(playlistId);
    
            }
            e.stopImmediatePropagation();
        });
        $('.ou-video-playlist--left-nav').on('click', function (e) {
            if (($(this).attr('aria-disabled') === 'false') && ($(this).attr('data-triggered') === 'false')) {
                var $this = $(this);
                $this.attr('data-triggered', 'true');
    
                var mainVideo = $(this).siblings('.ou-video-playlist-carousel-container').find('.ou-video-playlist__video-main');
                var videoCarousel = $(this).closest('.ou-video-playlist-carousel-wrapper');
    
                var iframeElement = $(mainVideo).find('iframe');
                $(iframeElement).attr('src', $(iframeElement).attr('src'));
    
                $(mainVideo).prevAll().animate({ 'left': '+=140px' }, 300);
                $(mainVideo).nextAll().animate({ 'left': '+=140px' }, 300);
    
                $(mainVideo).prev().css({ 'transform': 'scale(1)', 'opacity': '1', 'z-index': '3', 'marginTop': '0px' });
                $(mainVideo).prev().prev().css({ 'transform': 'scale(0.9)', 'opacity': '0.5', 'z-index': '2', 'marginTop': '-12px' });
                $(mainVideo).prev().prev().prev().css({ 'left': '-280px', 'transform': 'scale(0.8)', 'opacity': '0.3', 'z-index': '1', 'marginTop': '-6px' });
                $(mainVideo).css({ 'left': '+=140px', 'transform': 'scale(0.9)', 'opacity': '0.5', 'z-index': '2', 'marginTop': '-12px' });
                $(mainVideo).next().css({ 'transform': 'scale(0.8)', 'opacity': '0.3', 'z-index': '1', 'marginTop': '-6px' });
                $(mainVideo).next().nextAll().css({ 'transform': 'scale(0)', 'opacity': '0' });
    
                $(mainVideo).find('iframe').attr('tabindex', '-1');
                $(mainVideo).prev().find('iframe').attr('tabindex', '0');
                $(mainVideo).find('.ou-video-playlist-content').find('a').last().removeClass('ou-videoContent-link');
                $(mainVideo).prev().find('.ou-video-playlist-content').find('a').last().addClass('ou-videoContent-link');
    
                $(mainVideo).removeClass('ou-video-playlist__video-main').prev().addClass('ou-video-playlist__video-main');
    
                var currentVideo = parseInt($(videoCarousel).attr('data-current-video'));
                var videoContent = $(videoCarousel).find('.ou-video-playlist-content');
                $(videoContent).eq((currentVideo) - 1).css('opacity', '0');
                $(videoContent).eq((currentVideo) - 2).animate({ 'opacity': 1 }, 750, function () {
                    $this.attr('data-triggered', 'false');
                });
                $(videoCarousel).attr('data-current-video', currentVideo - 1);
    
                $(mainVideo).prev().find('iframe').focus();
    
                var playlistId = $(this).closest('.ou-video-playlist-modal-wrapper').attr('id');
                CheckVideoPlaylistNav(playlistId);
                SetVideoPlaylistNavLabels(playlistId);
    
            }
            e.stopImmediatePropagation();
        });
    
        $('.ou-video-playlist--right-nav').on('keydown', function (e) {
            if (e.which === 9) {
                e.preventDefault();
                if (e.shiftKey) {
                    $(this).siblings('.ou-video-playlist-carousel-container').find('.ou-video-playlist__video-main').find('iframe').focus();
                }
                else {
                    $(this).closest('.ou-video-playlist-modal-body').siblings('.ou-modal-close-button').focus();
                }
            }
            e.stopImmediatePropagation();
        });
        $('.ou-video-playlist--left-nav').on('keydown', function (e) {
            if (e.which === 9) {
                e.preventDefault();
                if (e.shiftKey) {
                    $(this).closest('.ou-video-playlist-modal-body').siblings('.ou-modal-close-button').focus();
                }
                else {
                    $(this).siblings('.ou-video-playlist-carousel-container').find('.ou-video-playlist__video-main').find('iframe').focus();
                }
            }
            e.stopImmediatePropagation();
        });
        $('.ou-video-playlist-close').on('keydown', function (e) {
            if (e.which === 9) {
                e.preventDefault();
                if (e.shiftKey) {
                    $(this).siblings('.ou-video-playlist-modal-body').find('.ou-video-playlist--right-nav').focus();
                }
                else {
                    $(this).siblings('.ou-video-playlist-modal-body').find('.ou-video-playlist--left-nav').focus();
                }
            }
            e.stopImmediatePropagation();
        });
        $.each($(".ou-video-playlist-content"), function (ind, videoContent) {
            $(videoContent).find('a').last().on('keydown', function (e) {
                if (e.which === 9 && (!e.shiftKey)) {
                    e.preventDefault();
                    $(this).closest('.ou-video-playlist-carousel-container').siblings('.ou-video-playlist--right-nav').focus();
                }
                e.stopImmediatePropagation();
            });;
        });
        $('.ou-playlist-video iframe').on('keydown', function (e) {
            if (e.which === 9 && e.shiftKey) {
                e.preventDefault();
                $(this).closest('.ou-video-playlist-carousel-wrapper').find('.ou-video-playlist--left-nav').focus();
            }
            e.stopImmediatePropagation();
        });

        /*Video Playlist Banner*/
        $('.ou-link--video-playlist').off().on('click', function (event) {
            event.preventDefault();
            var $body = $('body');
            var showModal = $(this).attr('data-modal');
            $(showModal).fadeIn();
    
            var showModalOverlay = $(this).attr('data-modal-overlay');
            $(showModalOverlay).fadeIn();
    
            var $bodyWidth = $body.innerWidth();
            var totalScroll = $(window).scrollTop();
            $body.css({ 'overflow': 'hidden', 'width': $bodyWidth, 'position': 'fixed', 'top': -totalScroll });
    
            $(showModal).find('.ou-video-playlist--left-nav').focus();
            $(showModal).find('.ou-video-playlist__video-main').find('.ou-programme-article-iframe-overlay').fadeIn().delay(3500).fadeOut(2000);
    
            $('.ou-modal-close-button').off().on('click', function (e) {
                var iframeList = $(this).siblings('.ou-video-playlist-modal-body').find('iframe');
                $.each($(iframeList), function (ind, iframeItem) {
                    var iframeSrc = $(iframeItem).attr('src');
                    $(iframeItem).attr('src', '');
                    $(iframeItem).attr('src', iframeSrc);
                });
                var bodyPosition = $body.offset().top;
                $body.css({ 'overflow': 'auto', 'width': 'auto', 'position': '', 'top': '' });
                $(window).scrollTop(-bodyPosition);
                $(showModal).fadeOut();
                $(showModalOverlay).fadeOut();
            });
            $('.ou-modal-close-button').on('keydown', function (e) {
                if (e.which === 9) {
                    e.preventDefault();
                    if (e.shiftKey) {
                        $(this).siblings('.ou-video-playlist-modal-body').find('.ou-video-playlist--right-nav').focus();
                    }
                    else {
                        $(this).siblings('.ou-video-playlist-modal-body').find('.ou-video-playlist--left-nav').focus();
                    }
                }
                e.stopImmediatePropagation();
            });
        });
        $.each($(".ou-video-playlist-banner-wrapper"), function (ind, playlistBanner) {
            var showModal = $(playlistBanner).find('.ou-link--video-playlist').attr('data-modal');
            SetVideoCount(showModal, playlistBanner);
            VideoPlaylistOnLoad(showModal);
            CheckVideoPlaylistNav(showModal.substring(1));
            SetVideoPlaylistNavLabels(showModal.substring(1));
        });


        /*Iframe on focus*/
        $('iframe').on('focusin', function (e) {
            $(this).siblings('.ou-programme-article-iframe-overlay').fadeIn().delay(3500).fadeOut(2000);
            e.stopImmediatePropagation();
        });
        $('iframe').on('focusout', function (e) {
            $(this).siblings('.ou-programme-article-iframe-overlay').fadeIn(1000);
            e.stopImmediatePropagation();
        });

        /*Playlist Video Overlay*/
        var $playlistVideo = $('.ou-video-playlist-modal-wrapper .ou-article-video-wrapper');
        $playlistVideo.off().on('mouseenter click touchstart', function (e) {
            e.preventDefault();
            $(this).find('.ou-programme-article-iframe-overlay').fadeOut(1500);
            e.stopImmediatePropagation();
        });


        /*Site Wide Message*/
        if ($('.ou-site-message-modal-wrapper').length) {

            var $body = $('body');

            var $bodyWidth = $body.innerWidth();
            var totalScroll = $(window).scrollTop();
            $body.css({ 'overflow': 'hidden', 'width': $bodyWidth, 'position': 'fixed', 'top': -totalScroll });
        }

        
        $('.ou-site-message-modal-wrapper').find('a').last().addClass('ou-site-message-link--last');
        $('.ou-site-message-close').on('click', function () {
            var bodyPosition = $body.offset().top;
            $body.css({ 'overflow': 'auto', 'width': 'auto', 'position': '', 'top': '' });
            $(window).scrollTop(-bodyPosition);

            $('.ou-site-message-modal-wrapper').fadeOut();
            $('.ou-site-message-modal-overlay').fadeOut();
        });
        $('.ou-site-message-close').on('keydown', function (e) {
            if (e.which === 9) {
                if (e.shiftKey) {
                    e.preventDefault();
                    $(this).closest('.ou-site-message-modal-wrapper').focus();
                }
                else {
                    if (!$('.ou-site-message-link--last').length) {
                        e.preventDefault();
                        $(this).closest('.ou-site-message-modal-wrapper').focus();
                    }
                }
                e.stopImmediatePropagation();
            }
        });
        $('.ou-site-message-modal-wrapper').on('keydown', function (e) {
            if (e.which === 9 && e.shiftKey) {
                e.preventDefault();
                $(this).find('.ou-site-message-close').focus();
            }
            else if (e.which === 27) {
                e.preventDefault();
                $(this).find('.ou-site-message-close').trigger('click');
            }
            e.stopImmediatePropagation();
        });


        $('.ou-site-message-link--last').on('keydown', function (e) {
            if (e.which === 9 && !e.shiftKey) {
                e.preventDefault();
                $(this).closest('.ou-site-message-modal-container').find('.ou-site-message-close').focus();
            }
        });

        /*Article Image hyperlinks*/
        $('.ou-latest-article').on('click', function (event) {
            this.querySelector('.ou-link--primary').click();
            event.stopImmediatePropagation();
        });
        $('.ou-latest-article .ou-link--primary').on("click", function (e) {
            e.stopPropagation();
        });
        $('.ou-sub-article-image-container').on('click', function (event) {
            var articleContent = $(this).siblings('.ou-sub-article-content');
            $(articleContent)[0].querySelector('.ou-sub-article-link').click();
            event.stopImmediatePropagation();
        });
        $('.ou-bbc-article-banner').on('click', function (event) {
            var articleContent = $(this).find('.ou-bbc-article-banner-content-wrapper');
            $(articleContent)[0].querySelector('.ou-link--primary').click();
            event.stopImmediatePropagation();
        });
        $('.ou-inner-article-content-wrapper').on('click', function (event) {
            this.querySelector('.ou-link--primary').click();
            event.stopImmediatePropagation();
        });
        $('.ou-search-result-thumbnail-container').on('click', function (event) {
            var searchResultContent = $(this).siblings('.ou-search-result-content');
            $(searchResultContent)[0].querySelector('.ou-link--search-result').click();
            event.stopImmediatePropagation();
        });

        //DOM Mutation Observer
        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
        function CheckDOMMutation(targetElement, mutationHandler) {
            if (!targetElement) return;
            if (MutationObserver) {
                var mutationObserver = new MutationObserver(mutationHandler);
                mutationObserver.observe(targetElement, {
                    childList: true,
                    subtree: true
                });
                return mutationObserver;
            }
        }

        //HandleSearchResultsMutation
        var bodyElement = $('body')[0];
        CheckDOMMutation(bodyElement, function () {
            $('.ou-search-result-thumbnail-container').off().on('click', function (event) {
                var searchResultContent = $(this).siblings('.ou-search-result-content');
                $(searchResultContent)[0].querySelector('.ou-link--search-result').click();
                event.stopImmediatePropagation();
            });
        });

        /*Carousel Swipe*/
        $.fn.CarouselSwipe = function () {
            var targetCarousel = $(this);
    
            targetCarousel.off('touchstart').on('touchstart', function (event) {
                var startPosX = 0;
                var endPosX = 0;
                var startPosY = 0;
                var endPosY = 0;
                var carouselOffsetX = 0;
                var carouselOffsetY = 0;
                var highestTouchPosY = 0;
                var lowestTouchPosY = 0;
    
                startPosX = event.touches[0].clientX;
                startPosY = event.touches[0].clientY;
                highestTouchPosY = startPosY;
                lowestTouchPosY = startPosY;
    
                targetCarousel.off('touchmove').on('touchmove', function (event) {
                    var currentTouchPosY = event.touches[0].clientY;
                    if (currentTouchPosY > highestTouchPosY) {
                        highestTouchPosY = currentTouchPosY;
                    }
                    if (lowestTouchPosY === 0) {
                        lowestTouchPosY = currentTouchPosY;
                    }
                    else if (currentTouchPosY < lowestTouchPosY) {
                        lowestTouchPosY = currentTouchPosY;
                    }
                    event.stopImmediatePropagation();
    
                });
    
                targetCarousel.off('touchend').on('touchend', function (event) {
                    endPosX = event.changedTouches[0].clientX;
                    endPosY = event.changedTouches[0].clientY;
                    carouselOffsetY = Math.abs(startPosY - endPosY);
                    carouselOffsetX = Math.abs(startPosX - endPosX);
                    let highestOffsetY = Math.abs(startPosY - highestTouchPosY);
                    let lowestOffsetY = Math.abs(startPosY - lowestTouchPosY);
                    if ((carouselOffsetX > carouselOffsetY) && (carouselOffsetX > 15) && (highestOffsetY < 110) && (lowestOffsetY < 110)) {
                        if (startPosX > endPosX) {
                            if (targetCarousel.hasClass('ou-inner-article-carousel')) {
                                $(targetCarousel).siblings('.nav-right').click();
                            }
                            else if (targetCarousel.hasClass('ou-inner-article-directory-carousel')) {
                                $(targetCarousel).find('.nav-right-scroll').click();
                            }
                            else if (targetCarousel.hasClass('ou-slide-carousel-wrapper')) {
                                $(targetCarousel).find('.ou-image-slider-nav--right').click();
                            }
                        }
                        else {
                            if (targetCarousel.hasClass('ou-inner-article-carousel')) {
                                $(targetCarousel).siblings('.nav-left').click();
                            }
                            else if (targetCarousel.hasClass('ou-inner-article-directory-carousel')) {
                                $(targetCarousel).find('.nav-left-scroll').click();
                            }
                            else if (targetCarousel.hasClass('ou-slide-carousel-wrapper')) {
                                $(targetCarousel).find('.ou-image-slider-nav--left').click();
                            }
                        }
                    }
                    event.stopImmediatePropagation();
                });
                event.stopImmediatePropagation();
            });
        }

        $('.ou-inner-article-carousel').each(function () {
            $(this).CarouselSwipe();
        });
        $('.ou-inner-article-directory-carousel').each(function () {
            $(this).CarouselSwipe();
        });
        $('.ou-slide-carousel-wrapper').each(function () {
            $(this).CarouselSwipe();
        });
        
    });
  
    if (!hasMounted) {
        return null;
    }


    return (
        <React.Fragment key="bbc_portal_layout">

            <a href="#main-Content" className="ou-skip-content">Skip to main content <i className="fa fa-arrow-circle-down"></i></a>

            <Header />
            <SiteWideMessage /> 

            {children}

            <Helmet>              
                <link rel="preload, stylesheet"
                    href="https://maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css" 
                    media="all"
                    as="style" 
                />
                
                <meta http-equiv="cache-control" content="no-cache" />
                
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="use-credentials" />
                <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&display=swap" rel="stylesheet" />
                <script async src={searchBoxEngine}></script>


                
            </Helmet>
                
            <Footer/>
            
        </React.Fragment>
    )

}

function useHasMounted() {
    
    const [hasMounted, setHasMounted] = React.useState(false);
    
    React.useEffect(() => {
      setHasMounted(true);
    }, []);

    return hasMounted;
}