import React from "react";
import { IsEmpty, formatDateMonthYear } from '../../helpers/HelperFunctions.js'
import PublishedByAuthor from "../PublishedByAuthor/PublishedByAuthor.js"
import PublishedByAuthorModal from "../PublishedByAuthor/PublishedByAuthorModal.js";
import Image from "../AssetManagement/Image.js"

export default function LayoutPublishedBy(props) {
    
    if (IsEmpty(props.publishDate)) {
      return null  
    } else {
    

    // format published date
   const publishedDate = formatDateMonthYear(props.publishDate);

   const OUExpertsCount = props.OUExperts.value.length;

   // Authors array to hold combination of OUExperts and guest authors
   var authors = [];
   var totalAuthorCount=0;

   // Loop through OUExperts and add data to authors array
   props.OUExperts.value.forEach(elements => {
       authors.push({name: elements.elements.name.value, link: "#", id: elements.id});
       totalAuthorCount++;
   });

   // If we have less than 3 OU experts then we can add guest authors to the authors array
   if (totalAuthorCount < 3)
   {
       // Check guest author 1 and add to array
       if (!IsEmpty(props.guestAuthor1Name.value) && !IsEmpty(props.guestAuthor1Title))
       {
            authors.push({name: props.guestAuthor1Title.name + " " + props.guestAuthor1Name.value, link: "", id: "ga1"});
            totalAuthorCount++;
       } 

       // Check guest author 2 and add to array
       if (totalAuthorCount < 3 && !IsEmpty(props.guestAuthor2Name.value) && !IsEmpty(props.guestAuthor2Title))
       {
            authors.push({name: props.guestAuthor2Title.name + " " + props.guestAuthor2Name.value, link: "", id: "ga2"});
            totalAuthorCount++;
       }

       // Check guest author 3 and add to array
       if (totalAuthorCount < 3 && !IsEmpty(props.guestAuthor3Name.value) && !IsEmpty(props.guestAuthor3Title))
       {
            authors.push({name: props.guestAuthor3Title.name + " " + props.guestAuthor3Name.value, link: "", id: "ga3"});
            totalAuthorCount++;
       }       
   }
    
    return ( 
        <>
            {totalAuthorCount > 0 &&
               <div className="ou-article-author-info-wrapper">
                    <div className="ou-article-author-info-container">
                        
                        {/* if there is only one author in total and that author is an OU Expert, show the image */}
                        {(OUExpertsCount === 1 && totalAuthorCount === 1) &&
                            <div className="ou-article-author-image">
                                <Image 
                                    image={props.OUExperts.value[0].elements.profile_picture.value}
                                    MaxWidth="533"
                                    MaxHeight="510"
                                /> 
                            </div>
                            
                        }
                      
                        <div className="ou-article-author-profile">
                            <p className="ou-article-author-info">
                                <span>Written by:</span>
                                &nbsp;
                                <PublishedByAuthor
                                    Authors={authors}
                                />
                            </p>
                        
                            <p className="ou-article-published-date">
                                <span>Date Published:</span>
                                &nbsp;
                                <span>{publishedDate}</span>
                            </p>
                        </div>
                        
                        {/* create modal window for each 'OU expert author' */}
                        {props.OUExperts.value.map(({ elements, id }) => (
                            <React.Fragment key={"author-modal-" + id}>
                                <PublishedByAuthorModal 
                                    profile_picture={elements["profile_picture"].value}
                                    expertName={elements["name"].value}
                                    expertJobTitle={elements["job_title"].value}
                                    expertBio={elements["biography"].value}
                                    modalid={id}
                                    linkUrl={elements["link_to_academic_profile"].value}
                                />
                            </React.Fragment>
                        ))}  
                    </div>
                </div>
            }

        </>
        )
    }
}





