import React from "react";
import { IsEmpty, IsEmptyArray } from '../../helpers/HelperFunctions.js'
import Video from './Video.js'
import { StaticImage} from "gatsby-plugin-image"

export default function VideoPlaylist(props) {
    
    if (IsEmpty(props.listOfVideos) || (IsEmpty(props.playlistTitle)) ) {
      return null  
    } else {
            
    return ( 
        <>
        <div className="ou-video-playlist-modal-overlay" id={"videoPlaylistOverlay" + props.overlayID}></div>
        <section className="ou-video-playlist-modal-wrapper" id={"videoPlaylist" + props.overlayID}>
            <div className="ou-video-playlist-modal-container">
                <button className="ou-modal-close-button ou-video-playlist-close" aria-label="Close"></button>
                <div className="ou-video-playlist-modal-body">
                    <div className="ou-video-playlist-carousel-header-wrapper">
                        <h3 className="ou-video-playlist-header">{props.playlistTitle}</h3>
                        <div className="ou-video-playlist-count">
                            <div className="ou-video-playlist-icon">
                                <StaticImage src="../../images/Video_playlist.svg" alt="video playlist"/>
                            </div>
                            <span className="videoCount"></span>
                        </div>
                    </div>
                    <div className="ou-video-playlist-carousel-wrapper" data-current-video="1" data-video-count="1">
                        <button className="ou-video-playlist--left-nav" aria-disabled="false" aria-label="Previous video" data-triggered="false"><i className="fa fa-chevron-left"></i></button>
                        <div className="ou-video-playlist-carousel-container">
                            {!IsEmptyArray(props.listOfVideos) &&
                                <div className="ou-video-playlist-carousel">
                                    {props.listOfVideos.value.map(({ elements }, i) => (
                                        <React.Fragment key={props.listOfVideos.value[i].id}>
                                            <Video
                                                video_iframe={elements.external_iframe_link.value} 
                                                video_title={elements.video_title.value} 
                                                video_duration={elements.video_duration.value}
                                                divClassName={i>0 ? 'ou-playlist-video' : 'ou-playlist-video ou-video-playlist__video-main'}
                                                video_index={i+1}
                                                video_Description={elements.video_caption_text.value}
                                                video_transcript={elements.transcript.value}
                                            />   
                                        </React.Fragment>                                   
                                    ))} 
                                </div>
                            }
                        </div>
                        <button className="ou-video-playlist--right-nav" aria-disabled="false" aria-label="Next video" data-triggered="false"><i className="fa fa-chevron-right"></i></button>
                    </div>
                </div>
            </div>
        </section>
        </>
        )
    }
}





