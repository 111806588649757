const React = require("react") // eslint-disable-line
const Layout = require("./src/components/layouts/layout") // eslint-disable-line

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [yOffset] - // Offset for header height, default = -80;
 */
  
// retry mechanism and a delay to allow time for page rendering
exports.onRouteUpdate = ({ location }) => {
	if (location.hash) {
		const id = location.hash.replace("#", ""); // Remove the '#' to get the element ID

		const scrollToElement = () => {
			const element = document.getElementById(id); // Try to find the element by ID

			if (element) {
				// Scroll to the element with smooth behavior
				element.scrollIntoView({ behavior: "smooth" });
			} else {
				// If element is not found, retry after 100ms
				setTimeout(scrollToElement, 100); // Retry after 100ms
			}
		};

		// Start the scroll process after 300ms (after the route is updated and the page is rendered)
		setTimeout(scrollToElement, 300);
	}
};
 