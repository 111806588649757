import React from "react"
import ModularContentProcessor from "./ModularContentProcessor.js";
import OUVideo from "../AssetManagement/OUVideo.js";
import { IsEmpty } from "../../helpers/HelperFunctions.js";


export default function LayoutAboutIntroduction(props) {
      
    return (

        <>
            <div className="ou-expanded-article-content">

                {!IsEmpty(props.title) &&
                    <p className="ou-group-heading">{props.title}</p>
                }

                <ModularContentProcessor
                    richText={props.richText}
                    layoutSection="AboutIntroduction"
                />
                
            </div>

            <div className="ou-article-video-wrapper" >
            
                {!IsEmpty(props.mediaAssets.value) && 
                    <OUVideo 
                        videoAsset={props.mediaAssets.value[0]}
                        iFrameContainerClass='ou-programme-article-iframe-container'
                        iFrameOverlayClass='ou-programme-article-iframe-overlay'
                    />
                }
            </div>
        </>
    )
}
