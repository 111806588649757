import React from "react"
import { IsEmpty } from "../../helpers/HelperFunctions"
import ModularContentProcessor from "./ModularContentProcessor"


export default function LayoutUnorderedList(props) {
    
    return (
        
        <>
            {!IsEmpty(props.listHeader) && 
                <h4 className="ou-main-list-items-header" tabIndex="0">
                    {props.listHeader}
                </h4>
            }

            {!IsEmpty(props.listItems) && 
                <div className="ou-main-list-items" tabIndex="0">
                <ul>
                    <ModularContentProcessor 
                        richText={props.listItems}
                        layoutSection="UnorderedListComponent"
                    />
                </ul>
                </div>
            }

        </>

    )

}