import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Link } from 'gatsby';
import { IsEmpty } from "../../helpers/HelperFunctions"
import ExploreTheOUGroup from "../exploretheou/ExploreTheOUGroup.js"


// 'queryless' implementation pattern of the component allowing JSON test data to 
// overwrite the static query.
export const PureFooter = ({ data }) => (
  
  <footer>

    <ExploreTheOUGroup />
    
    <section key="_footer" className="ou-footer-container">
      <div className="ou-footer-element-wrapper">
        
          {data.allKontentItemWebFooter.nodes[0].elements.footer_sections.value.map(({ elements }, index) => (          
            
            <FooterSection sectionHeading={elements.section_name.value}
                            sectionSubText={elements.section_introduction.value}
                            sectionLayoutStyle={elements.section_layout.value[0].name}
                            sectionLinks={elements.section_web_links} 
                            sectionKey={index}
                            key={"footer_section_" + index} />
          ))}   
      </div>
    </section>
    
  </footer>
)

// Default exported component with Static Query - utilises 'pure' version above for rendering.
export const Footer = props => (
  <StaticQuery 
        query = {graphql `query {
          allKontentItemWebFooter {
            nodes {
              elements {
                footer_sections {
                  value {
                    ... on kontent_item_web___footer_section {
                      elements {
                        section_introduction {
                          value
                        }
                        section_name {
                          value
                        }
                        section_layout {
                          value {
                            name
                          }
                        }
                        section_web_links {
                          value {
                            ... on kontent_item_web___link {
                              id
                              elements {
                                link {
                                  value
                                }
                                link_text {
                                  value
                                }
                                external_link {
                                  value {
                                    name
                                  }
                                }
                                link_image {
                                  value {
                                    ...AssetFragment
                                  }
                                }
                              }
                              system {
                                type
                              }
                            }
                            ... on kontent_item_web_page___subject {
                              id
                              system {
                                      type
                              }
                              elements {
                                url_slug {
                                  value
                                }
                                
                                subject_area {
                                  value {
                                    ... on kontent_item_core___subject_area {
                                      id
                                      elements {
                                        title {
                                          value
                                        }
                                      }
                                    }
                                    
                                  }
                                }
                              }
                            }
                            ... on kontent_item_web_page___ancillary {
                              id
                              elements {
                                page_url {
                                  value
                                }
                                web___hero_banner__title {
                                  value
                                }
                              }
                              system {
                                type
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }`}

        render = { data => <PureFooter {...props} data={data} />}
    />
)


export default Footer





// Handles display of a 'column' of the footer.
export const FooterSection = ({sectionHeading="",
                               sectionSubText="",
                               sectionLayoutStyle="vertical",
                               sectionLinks,
                               sectionKey}) => {

      return (
          
            <div className="ou-footer-element" >

                {/* Handle the links for the different types of Footer sections */}

                {sectionLayoutStyle === "Normal Section" && 
                    <StandardFooterSection sectionHeader={sectionHeading} sectionSubText={sectionSubText} links={sectionLinks} keyProp={sectionKey}/>
                }

                {sectionLayoutStyle === "Social Media Section" && 
                    <SocialMediaFooterSection sectionHeader={sectionHeading} sectionSubText={sectionSubText}/>
                }

                {sectionLayoutStyle === "Copyright Section" && 
                    <CopyrightFooterSection sectionHeader={sectionHeading}/>
                }

            </div>
          
      )
  
  }


// Handles the display of a "standard" footer section. ie. straight vertical list of links.
const StandardFooterSection = props => {
  
  const standardLinksSection = props.links.value.map( ( footerLink ) => (
    
    <React.Fragment key={footerLink.id}>

      {/* A footer link can be several things: A web link content type, A subject Area web page type or an Ancillary web page type. */}      
      {/* Check what we're dealing with */}

      {footerLink.system.type === "web___link" &&
      
        <DisplayWebLink _link={footerLink} />

      }

      {footerLink.system.type === "web_page___subject" &&
      
        <DisplaySubjectWebPageLink _link={footerLink} />

      }


    {footerLink.system.type === "web_page___ancillary" &&
      
        <DisplayAncillaryWebPageLink _link={footerLink} />

    }

    </React.Fragment>
  ));
  
  return (
      <>

          <span className="ou-footer-element-header">{props.sectionHeader}</span>

          {!IsEmpty(props.sectionSubText) && 
            <span dangerouslySetInnerHTML={{ __html:props.sectionSubText}}></span>}

          {standardLinksSection}

      </>
  );

}


// Handles the display of a "Web Link" content type. These can be internal or external type links.
const DisplayWebLink = props => {

  return (
    <>

    {props._link.elements.external_link.value[0].name === "External to BBC site" && 
     
          <>

            {props._link.elements.link.value.length > 0 &&
                <a href={props._link.elements.link.value} aria-label={"Go to " + props._link.elements.link_text.value + " page"}>{props._link.elements.link_text.value}</a>
            }
            {props._link.elements.link.value.length === 0 &&
              <span style={{color: "#fff", font: "normal normal normal 16px/28px Arial"}}>{props._link.elements.link_text.value}</span>
            }

          </>
      }

      {props._link.elements.external_link.value[0].name === "Internal to BBC site" && 
    
          <>
            <Link 
                to={props._link.elements.link.value}
                aria-label={"Go to " + props._link.elements.link_text.value + " page"}
                key={props._link.elements.id}>
                {props._link.elements.link_text.value}
            </Link>
          </>

      }

      {props._link.elements.external_link.value[0].name === "Link Markup" && 
                   
          <>
              <span dangerouslySetInnerHTML={{ __html: props._link.elements.link.value }} />                           
          </>

      }         

    </>
  );

}

// Handles the display of a "Subject Web Page" content type links. These will all be internal.
const DisplaySubjectWebPageLink = props => {

  return (
    <>
        {props._link.elements.url_slug.value.length > 0 &&

          <Link 
            to={props._link.elements.url_slug.value}
            aria-label={"Go to " + props._link.elements.subject_area.value[0].elements.title.value + " page"}
            key={props._link.id}>
            {props._link.elements.subject_area.value[0].elements.title.value}
          </Link>
            
        }
        {props._link.elements.url_slug.value.length === 0 &&
          <span>{props._link.elements.subject_area.value[0].elements.title.value}</span>
        }
    </>
  );
}

const DisplayAncillaryWebPageLink = props => {

  return (
    <>
      {props._link.elements.page_url.value.length > 0 &&
            <a href={props._link.elements.page_url.value}>{props._link.elements.web___hero_banner__title.value}</a>
      }
      {props._link.elements.page_url.value.length === 0 &&
        <span>{props._link.elements.web___hero_banner__title.value}</span>
      }
    </>
  );
}



// Handles the display of a "standard" footer section. ie. straight vertical list of links.
const SocialMediaFooterSection = props => {
  
  return (
      <>
          <span className="ou-footer-element-header">{props.sectionHeader}</span>
          {props.sectionSubText && (<div dangerouslySetInnerHTML={{ __html:props.sectionSubText}}></div>)}

          <div className="ou-footer-socials-container">
              <div className="ou-footer-social-media-element"><i className="fa fa-instagram"></i><span><a href="https://www.instagram.com/theopenuniversity/" aria-label="Go to Open University Instagram page">Instagram</a></span></div>
              <div className="ou-footer-social-media-element"><i className="fa fa-twitter"></i><span><a href="https://twitter.com/OpenUniversity" aria-label="Go to Open University Twitter page">Twitter</a></span></div>
              <div className="ou-footer-social-media-element"><i className="fa fa-facebook"></i><span><a href="https://www.facebook.com/theopenuniversity/" aria-label="Go to Open University Facebook page">Facebook</a></span></div>
              <div className="ou-footer-social-media-element"><i className="fa fa-youtube"></i><span><a href="https://www.youtube.com/user/theopenuniversity" aria-label="Go to Open University YouTube page">YouTube</a></span></div>
          </div>
      </>
  );

}

const CopyrightFooterSection = props => {

  return (
      <>
        <span className="ou-footer-element-header ou-footer-copyright">&copy; {props.sectionHeader}</span>
      </>
  );

}



export function FooterLinkValidation(linkTextValue, linkType, sectionLayoutStyle) {

  var _isValid = true;

  // All params can't be empty.
  if (IsEmpty(linkTextValue) && IsEmpty(linkType) && IsEmpty(sectionLayoutStyle))
    _isValid = false;
  

  // Must have some text.
  if (IsEmpty(linkTextValue) )
    _isValid = false;


  // Layout style is either Vertical or Horizontal.
  if (!(sectionLayoutStyle === "vertical" || sectionLayoutStyle === "social_media" || sectionLayoutStyle === "copyright_section"))
     _isValid = false;

  // Link must be internal or external
  if (!(linkType === "Internal to BBC site" || linkType === "External to BBC site"))
     _isValid = false;


  return _isValid;
}