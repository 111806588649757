import React from "react"
import ModularContentProcessor from "../StructuredContent/ModularContentProcessor.js";


export default function LayoutCentralisedBlock(props) {
    
    // STUB FUNCTION - Requires work

    return (
        
        <>
            <div className="ou-expanded-article-content">
                <ModularContentProcessor
                    richText={props.richText}
                    layoutSection="CentralisedBlockText"
                />
            </div>
        </>

    )

}