// Handles the display of a single 'Explore The OU' data component.
import React from "react";
import Image from "../AssetManagement/Image.js"
import Button from "../Button.js"
import PropTypes from "prop-types"
import ModularContentProcessor from "../StructuredContent/ModularContentProcessor.js";
import { IsEmpty } from '../../helpers/HelperFunctions.js'

export default function ExploreTheOUCard({cardImage=null,
                                          cardTitle='',
                                          cardText,
                                          cardLink='',
                                          cardKey='',
                                          cardButtonText=''}) {
    
    const buttonText = (!IsEmpty(cardButtonText)) ? cardButtonText : "OU website";                    

    return (
        <>
        {cardImage &&
            <div className="about-ou-element-image">
                
                    <Image 
                    image={cardImage} 
                    />
            </div>
        }
        <div className="ou-website-card">
            
            <h3 className="ou-website-card-title">{cardTitle}</h3>

            <ModularContentProcessor
                    richText={cardText}
                    layoutSection="AboutTheOUCard"
            />
 
            <Button 
                link={cardLink}
                text={buttonText}
                class="ou-link--primary"
                keyProp={cardKey}
                linkType="External"
                label={"Go to " + cardButtonText + " page"}
            />

        </div>
        </>
    )
}
  
ExploreTheOUCard.propTypes = {
    cardTitle: PropTypes.string.isRequired,
    cardText: PropTypes.object.isRequired,
    cardLink: PropTypes.string.isRequired,
    cardKey: PropTypes.string.isRequired,
    cardButtonText: PropTypes.string.isRequired,
    cardImage: PropTypes.array,
  }
