import React from "react"
import { IsEmpty } from "../../helpers/HelperFunctions"


export default function LayoutAncillarySubTitle(props) {
    
    const _IsThisAQuestion = IsEmpty(props._question.value) ? "No" : props._question.value[0].name;

    return (
        
        <>
            {(!IsEmpty(props.subTitle) && _IsThisAQuestion === "Yes") && 
                <p className="ou-main-sub-title" tabIndex="0"><i className="fa fa-question-circle"></i>{props.subTitle}</p>
            }

            {(!IsEmpty(props.subTitle) && _IsThisAQuestion === "No") && 
                <p className="ou-main-sub-title" tabIndex="0">{props.subTitle}</p>
            }

        </>

    )

}






