import React from "react"
import { graphql, StaticQuery } from "gatsby"
import ExploreTheOU from "./ExploreTheOU.js"
import ExploreTheOUCard from "./ExploreTheOUCard.js"



// 'queryless' implementation pattern of the component allowing JSON test data to 
// overwrite the static query.
export const PureExploreTheOUGroup = ({ data }) => (

    <section className="about-ou-catalogue">

      <div><h2 className="about-ou-header">{data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.title.value}</h2></div>
      <div className="about-ou-container-wrapper">

        <div className="about-ou-container">

                {/* Row #1 Left - study At The OU*/}
                <div className="about-ou-element wide">

                  <ExploreTheOU 
                    imageObject={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.row_1_left_tile.value[0].elements.link_image.value} 
                    exploreLink={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.row_1_left_tile.value[0].elements.link.value} 
                    exploreLinkText={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.row_1_left_tile.value[0].elements.link_text.value} 
                  />

                </div>

                {/* Row 1 - Right - Free OU Poster*/}
                <div className="about-ou-element square">

                  <ExploreTheOU 
                      imageObject={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.row_1_right_tile.value[0].elements.link_image.value} 
                      exploreLink={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.row_1_right_tile.value[0].elements.link.value} 
                      exploreLinkText={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.row_1_right_tile.value[0].elements.link_text.value} 
                      />

                  </div>
                
                  {/* Row 2 - Left - For Study*/}
                  <div className="about-ou-element square">

                  <ExploreTheOU 
                      imageObject={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.row_2_left_tile.value[0].elements.link_image.value} 
                      exploreLink={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.row_2_left_tile.value[0].elements.link.value} 
                      exploreLinkText={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.row_2_left_tile.value[0].elements.link_text.value} 
                      />

                  </div>

                {/* Row 2 - Central - Explore Subjects*/}
                <div className="about-ou-element tall">

                  <ExploreTheOU 
                      imageObject={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.row_2_central_tile.value[0].elements.link_image.value} 
                      exploreLink={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.row_2_central_tile.value[0].elements.link.value} 
                      exploreLinkText={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.row_2_central_tile.value[0].elements.link_text.value} 
                      />

                </div>


                {/* Row 2 - Right - Free material */}
                <div className="about-ou-element square">

                  <ExploreTheOU 
                        imageObject={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.row_2_right_tile.value[0].elements.link_image.value} 
                        exploreLink={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.row_2_right_tile.value[0].elements.link.value} 
                        exploreLinkText={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.row_2_right_tile.value[0].elements.link_text.value} 
                        />

                </div>


                {/* Row 3 - left - Free courses */}
                <div className="about-ou-element square">

                  <ExploreTheOU 
                      imageObject={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.row_3_left_tile.value[0].elements.link_image.value} 
                      exploreLink={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.row_3_left_tile.value[0].elements.link.value} 
                      exploreLinkText={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.row_3_left_tile.value[0].elements.link_text.value} 
                      />

                </div>


                {/* Square #8 - Student Life */}
                <div className="about-ou-element square">

                  <ExploreTheOU 
                      imageObject={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.row_3_right_tile.value[0].elements.link_image.value} 
                      exploreLink={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.row_3_right_tile.value[0].elements.link.value} 
                      exploreLinkText={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.row_3_right_tile.value[0].elements.link_text.value} 
                      />

                </div>



                {/* Square #3 - Large 50 years */}
                <div className="about-ou-element large">

                   <ExploreTheOUCard
                      cardTitle={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.featured_item_title.value} 
                      cardText={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.featured_item_introduction} 
                      cardLink={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.featured_item.value[0].elements.link.value} 
                      cardKey={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.featured_item.value[0].id}
                      cardButtonText={data.allKontentItemWebExploreTheOuGroup.edges[0].node.elements.featured_item.value[0].elements.link_text.value} 
                    />
                    

                </div>

               

               

               

               

               
        </div>
      </div>
  </section>

)

// Default exported component with Static Query - utilises 'pure' version above for rendering.
export const ExploreTheOUGroup = props => (
  <StaticQuery 
        query = {graphql `query MyQuery {
          allKontentItemWebExploreTheOuGroup {
            edges {
              node {
                elements {
                  featured_item {
                    value {
                      ...WebLinkFragment
                    }
                  }
                  row_1_left_tile {
                    value {
                      ...WebLinkFragment
                    }
                  }
                  row_1_right_tile {
                    value {
                      ...WebLinkFragment
                    }
                  }
                  row_2_left_tile {
                    value {
                      ...WebLinkFragment
                    }
                  }
                  row_2_central_tile {
                    value {
                      ...WebLinkFragment
                    }
                  }
                  row_2_right_tile {
                    value {
                      ...WebLinkFragment
                    }
                  }
                  row_3_left_tile {
                    value {
                      ...WebLinkFragment
                    }
                  }
                  row_3_right_tile {
                    value {
                      ...WebLinkFragment
                    }
                  }
                  featured_item_introduction {
                    value
                  }
                  featured_item_title {
                    value
                  }
                 
                  introduction {
                    value
                  }
                  title {
                    value
                  }
                }
              }
            }
          }
        }`}

        render = { data => <PureExploreTheOUGroup {...props} data={data} />}
    />
)

export default ExploreTheOUGroup