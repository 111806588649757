import React from "react"
import { IsEmpty } from "../../helpers/HelperFunctions"


export default function LayoutAncillarySubHeader(props) {
    
    return (
        
        <>
            {!IsEmpty(props.SubHeader) && 
                <h3 className="ou-main-sub-heading" tabIndex="0">
                    {props.SubHeader}
                </h3>
            }
        </>

    )

}