import React from "react"
import ModularContentProcessor from "./ModularContentProcessor.js";

export default function LayoutGetInvolved(props) {
   
    return (

        <>
            <div>
                {props.title &&
                <h3 className="ou-group-heading">{props.title}</h3>
                }

                <ModularContentProcessor
                    richText={props.richText}
                    layoutSection="AboutGetInvolved"
                />
            </div>
        </>
    )
}


