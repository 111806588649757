import React from "react"
import { IsEmpty } from "../../helpers/HelperFunctions";
import LayoutFiveFactsQuiz from "./LayoutFiveFactsQuiz"
import LayoutExpandedArticleQuiz from "./LayoutExpandedArticleQuiz";

export default function LayoutQuizController(props) {
         
    const _title = (!IsEmpty(props.content.elements.title.value)) ? props.content.elements.title.value : "";
    const _richTextElement = props.content;
    const _oddEvenDisplay = (!(IsEmpty(props.content.elements.background_style) || IsEmpty(props.content.elements.background_style.value))) ? props.content.elements.background_style.value[0].name : "white";
    
    return (
        
        <>
            {props.layoutSection === "FiveFacts" &&

                <LayoutFiveFactsQuiz 
                    title={_title}
                    richText={_richTextElement}
                    oddEvenDisplay={_oddEvenDisplay}
                /> 
            }

            {props.layoutSection === "ExpandedArticle" &&

                <LayoutExpandedArticleQuiz
                    title={_title}
                    richText={_richTextElement}
                    oddEvenDisplay={_oddEvenDisplay}
                /> 
            }

        </>     
    )

}