import React from "react";
import { IsEmpty } from '../../helpers/HelperFunctions.js'

export default function CopyrightImage(props) {
    
    if (IsEmpty(props.CopyrightCaption)) {
      return null  
    } else {
            
    return ( 
        <div className="ou-image-cc-wrapper">
            <div className="ou-image-cc-btn-container">
                <button className="ou-image-cc-btn" aria-label="View copyright details"><i className="fa fa-copyright" aria-hidden="true"></i></button>
            </div>
            <div className="ou-image-cc-content-container">
                <div className="ou-image-cc-content" dangerouslySetInnerHTML={{__html: props.CopyrightCaption}} /> 
            </div>
        </div>
        )
    }
}