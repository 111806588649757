import React from "react"
import ModularContentProcessor from "./ModularContentProcessor"
import { IsEmpty } from "../../helpers/HelperFunctions"


export default function Layout2Columns(props) {
   
    
    return (
        
        <>
        <section className="ou-priority-subject-areas-wrapper ou-two-column-layout">
       
        
            {!IsEmpty(props.titleText.value) &&
                <ModularContentProcessor
                    richText={props.titleText}
                    layoutSection="Layout2ColumnSectionHeader"
                />
            }
        
        
        <div className="ou-priority-areas-container">
            <div className="ou-priority-areas-column">  

                <p className="ou-priority-areas-title" dangerouslySetInnerHTML={{__html: props.column1Title.value}} />
                
                <ModularContentProcessor
                    richText={props.column1Body}
                    layoutSection="Layout2ColumnBody"
                />
                

            </div>
            <div className="ou-priority-areas-column">  

                <p className="ou-priority-areas-title" dangerouslySetInnerHTML={{__html: props.column2Title.value}} />
                
                <ModularContentProcessor
                    richText={props.column2Body}
                    layoutSection="Layout2ColumnBody"
                />
            </div>
        </div>
        
        </section>
        </>        
    )

}


