import React from "react"
import {graphql, StaticQuery} from "gatsby"
import { Link } from 'gatsby';
import { IsEmpty, GenerateProgrammePageUrl } from "../../helpers/HelperFunctions.js"
import { StaticImage} from "gatsby-plugin-image"
import SearchBarOU from "../Search/SearchBarOU.js";

export const PureHeader = ({ data }) => (

  <header className="ou-bbc-header-container">
    <div className="ou-bbc-header--primary">
        {/* LOGO */}
        <div className="ou-bbc-header-logo" style={{textAlign:"center", display:"flex"}}>
          <div style={{display:"inline-block", verticalAlign:"middle", marginRight:"15px"}}>
              <a href="/"><StaticImage width="178" src='../../images/OU_Master_LOGO_WHITE_63mm.svg' alt="The Open University and BBC logos"/></a>
          </div>
          <div style={{display:"inline-block",marginLeft:"10px",marginRight:"10px", paddingTop:"10px",paddingBottom:"10px"}}>
              <a href="/"><StaticImage width="140" src='../../images/BBC.png' alt="The Open University and BBC logos" /></a>
          </div>
        </div>
       

        {/* Level 1 links */}
        

        <div className="ou-header-main">

          <div className="ou-header-row">
            <SearchBarOU />
          </div>

          <div className="ou-bbc-menu">

            <ul>

              {data.allKontentItemWebHeader.edges[0].node.elements.navigation_group.value[0].elements.navigation_links.value.map(({ elements }, index ) => (
                
                <HeaderLink  
                    linkTextValue ={elements.display_name.value} 
                    linkUrl = {elements.link.value}
                    categoryLinks = {elements.sub_menu_navigation_links}
                    newProgrammeLinks = {data.allKontentItemWebProgramme.edges}
                    key={"header_section_" + index}
                    allPages={data.allSitePage}
                    
                />
              
              ))}

              </ul>
          </div>

        </div>

        <div className="ou-mobile-nav-toggle-container ou-mobile-nav-inactive">
          <i className="fa fa-bars mobile-nav-toggle"></i>
        </div>
      </div>
      <div className="ou-bbc-header--secondary"></div>
  </header>
 )

export const Header = props => (

  <StaticQuery 
        query = {graphql `{
          allKontentItemWebHeader {
            edges {
              node {
                elements {
                  ou_logo {
                    value {
                      ...AssetFragment
                    }
                  }
                  navigation_group {
                    value {
                      ... on kontent_item_web___navigation___group {
                        id
                        elements {
                          navigation_links {
                            value {
                              ... on kontent_item_web___navigation_link {
                                id
                                elements {
                                  display_name {
                                    value
                                  }
                                  link {
                                    value
                                  }
                                  sub_menu_navigation_links {
                                    value {
                                      ... on kontent_item_web_page___subject {
                                        id
                                        elements {
                                          url_slug {
                                            value
                                          }
                                          subject_area {
                                            value {
                                              ... on kontent_item_core___subject_area {
                                                id
                                                elements {
                                                  title {
                                                    value
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          allSitePage {
            nodes {
              pageContext
              path
            }
          }             
          allKontentItemWebProgramme(
            filter: {elements: {new_programme: {value: {elemMatch: {name: {eq: "Yes"}}}}}}
          ) {
            edges {
              node {
                id
                elements {
                  url {
                    value
                  }              
                  programme_to_display {
                    value {
                      ... on kontent_item_bbc_programme {
                        id
                        elements {
                          title {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }`
      }

      render = { data => <PureHeader {...props} data={data} />}
    />
)


export default Header


const CategoryListItems = props => {
        
  // Iterates over the individual categories.
  const categories = props.listOfCategories.value.map( category => (
      
    <React.Fragment key={category.id}>
      <li>
        <Link 
          to={category.elements.url_slug.value} aria-label={"Go to " + category.elements.subject_area.value[0].elements.title.value + " programmes"}>
          {category.elements.subject_area.value[0].elements.title.value}
          </Link>
        </li>
    </React.Fragment>

  ));

  // Iterates over any new programmes.
  const newProgrammes = props.listOfNewProgrammes.map( ({ node} ) => (
      <React.Fragment key={node.id}>
          <li>
              <Link to={GenerateProgrammePageUrl(props.allPages, node.elements.url.value)} aria-label={"Go to " + node.elements.programme_to_display.value[0].elements.title.value + " programme"}>
                {node.elements.programme_to_display.value[0].elements.title.value}
              </Link>
          </li>
       </React.Fragment>
        ));
  
  return (
      <>
        <div className="ou-bbc-sub-menu-categories">
          <span className="ou-bbc-sub-menu-element-header">Categories<i className="fa fa-chevron-right"></i></span>
          <ul>
            {categories}
          </ul>
        </div>
        
        <div className="ou-bbc-sub-menu-latest-programmes">
          <span className="ou-bbc-sub-menu-element-header">Latest Programmes<i className="fa fa-chevron-right"></i></span>
          <ul>
            {newProgrammes}
          </ul>
        </div>
      </>
  );

}

export const HeaderLink = ({linkTextValue="Undefined", 
                            linkUrl="",
                            categoryLinks,
                            newProgrammeLinks,
                            allPages
                            }) => {

  return (
    <>

      {!IsEmpty(categoryLinks.value) &&
        
        <li className="ou-menu-dropdown">
        <button aria-label={"Open " + linkTextValue + " menu"} aria-expanded="false"><span className="ou-menu-dropdown-trigger">{linkTextValue}<i className="fa fa-caret-down"></i></span></button>
        <div className="ou-bbc-categories-sub-menu">
          <div className="ou-bbc-categories-sub-menu-container">
            <CategoryListItems parentLink={linkTextValue} listOfCategories={categoryLinks} listOfNewProgrammes={newProgrammeLinks} allPages={allPages}/>
          </div>
        </div>  
        </li>
        
      }

      {IsEmpty(categoryLinks.value) &&
        <li>
        <Link 
             to={linkUrl} aria-label={"Go to " + linkTextValue + " page"}>{linkTextValue}
        </Link>
        </li>
      }
    </>
  )
}

