import React from "react"
import LayoutCarousel from "./LayoutCarousel.js"

export default function LayoutFiveFactsCarousel(props) {

    const oddEven = (props.oddEvenDisplay === "Black") ? "ou-programme-article-wrapper article-odd" : "ou-programme-article-wrapper article-even";
    
    return (

        <section className={oddEven}>
            <div className="ou-programme-article-container">

                <LayoutCarousel
                    carouselTitle={props.carouselTitle}
                    listOfCarouselItems={props.listOfCarouselItems}
                    articleIndex={props.articleIndex}
                />  

            </div>
        </section>

    )
}


