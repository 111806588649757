import React from "react"
import LayoutQuiz from "./LayoutQuiz.js"

export default function LayoutFiveFactsQuiz(props) {

    const oddEven = (props.oddEvenDisplay === "Black") ? "ou-programme-article-wrapper article-odd" : "ou-programme-article-wrapper article-even";
    
    return (

        <section className={oddEven}>
            <div className="ou-programme-article-container">

                <LayoutQuiz
                    iFrameLink={props.richText.elements.quiz_iframe_link}
                    title={props.richText.elements.title}
                    body={props.richText.elements.body}
                />

            </div>
        </section>

    )
}


