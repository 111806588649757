import React from "react"
import { IsEmpty, SetVideoTitle } from '../../helpers/HelperFunctions.js'
import Button from "../../components/Button.js";


export default function OUVideo({videoAsset, 
                                iFrameContainerClass='ou-programme-article-iframe-container', 
                                iFrameOverlayClass='ou-programme-article-iframe-overlay'}) {
    
    if (IsEmpty(videoAsset))
        return null

    var transcriptAriaLabel = "Read transcript";
    if (IsEmpty(videoAsset.elements.transcript.value[0]) || IsEmpty(videoAsset.elements.transcript.value[0].description))
    {
        transcriptAriaLabel = "Read transcript";
    }
    else 
    {
        transcriptAriaLabel = videoAsset.elements.transcript.value[0].description;
    }  


    //const transcriptAriaLabel = (IsEmpty(videoAsset.elements.transcript.value[0])) ? "Read transcript" : videoAsset.elements.transcript.value[0].description;
        
    return (
        // Build markup for each iFrameLink here.
        <React.Fragment key={videoAsset.id}>

            {!IsEmpty(videoAsset.elements.external_iframe_link.value) && 

                    <>
                    <div className={iFrameContainerClass}>
                    <div dangerouslySetInnerHTML={{__html: SetVideoTitle(videoAsset.elements.external_iframe_link.value, videoAsset.elements.video_title.value)}} />
                    {/* check if the video has a duration before adding the play button overlay */}
                    {!IsEmpty(videoAsset.elements.video_duration.value) &&
                        <div className={iFrameOverlayClass}>
                            {videoAsset.elements.video_duration.value &&
                                <span>{videoAsset.elements.video_duration.value}</span>
                            }
                            {IsEmpty(videoAsset.elements.video_duration.value) &&
                                <span>{videoAsset.elements.video_duration.value}</span>
                            }
                            <i className="fa fa-play"></i>
                        </div>
                    }
                    </div>

                    {!IsEmpty(videoAsset.elements.video_caption_text.value) &&
                        <div className="ou-article-video-footer">
                            <p>{videoAsset.elements.video_caption_text.value}</p>
                        </div>
                    }

                    {!IsEmpty(videoAsset.elements.transcript.value) &&
                        <div>
                            <Button 
                                link={videoAsset.elements.transcript.value[0].url}
                                text={[<i key={"tile-button-" + videoAsset.id} className='fa fa-bars'></i>, "Transcript"]}
                                class="ou-link--primary ou-link--transcript"
                                target="_blank"
                                linkType="External"
                                label={transcriptAriaLabel}
                            />
                        </div>                    
                    }
                    </>
            }

            
        </React.Fragment>  
    )
} 
