import React, { useEffect } from "react"
import { IsEmpty } from "../../helpers/HelperFunctions"
import ModularContentProcessor from "../StructuredContent/ModularContentProcessor.js";

export default function LayoutQuiz(props) {

    const hasMounted = useHasMounted(); 

    useEffect(() => {
        if (hasMounted) {
                const script = document.createElement("script");

                script.src = "https://www.riddle.com/files/js/embed.js";
                script.async = true;
            
                document.body.appendChild(script);
            }
        }, [hasMounted])  

    if (!hasMounted) {
        return null;
    }
       
    if (IsEmpty(props.iFrameLink))
        return null;

    return (
        <>
            <section className="ou-quiz-wrapper">
                <div className="ou-quiz-container">
                    {!IsEmpty(props.title.value) &&
                        <h3 className="ou-quiz-title">{props.title.value}</h3>
                    }
                  
                    {!IsEmpty(props.body.value) &&
                        <ModularContentProcessor 
                            richText={props.body}
                            layoutSection={"quizDescription"}
                        />
                    }

                    <div className="ou-quiz-iframe-container" dangerouslySetInnerHTML={{__html: props.iFrameLink.value}} />

                </div>  
                
            </section>
       </>     
    )

}

function useHasMounted() {
    
    const [hasMounted, setHasMounted] = React.useState(false);
    
    React.useEffect(() => {
      setHasMounted(true);
    }, []);

    return hasMounted;
}