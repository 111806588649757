import React from "react"
import { IsEmpty } from "../../helpers/HelperFunctions";
import LayoutAboutTheProgramme from "./LayoutAboutTheProgramme";
import Layout5Facts from "./LayoutFiveFacts"
import LayoutExpandedArticle from "./LayoutExpandedArticle";
import LayoutInfographicThumbnailArticle from "./LayoutInfographicThumbnailArticle.js"
import LayoutTiledAsset from "./LayoutTiledAsset.js"
import LayoutGetInvolved from "./LayoutGetInvolved";
import LayoutAboutIntroduction from "./LayoutAboutIntroduction.js"



export default function LayoutTextWithEmbeddedAssets(props) {
         
    const _mode = (!IsEmpty(props.content.elements.layout_style.value)) ? props.content.elements.layout_style.value[0].name :  'Text Left, Image Right';
    const _title = (!IsEmpty(props.content.elements.title.value)) ? props.content.elements.title.value : "";
    const _oddEvenDisplay = (!(IsEmpty(props.content.elements.background_style) || IsEmpty(props.content.elements.background_style.value))) ? props.content.elements.background_style.value[0].name : "white";
    

    // The 'asset' field can contain more than 1 asset - pass the array in.
    const _mediaAssets = props.content.elements.asset;
    const _image = props.content.elements.asset.value;
    const _text = props.content.elements.body.value;
    const _richTextElement = props.content.elements.body;
    const _aboutBody = props.content.elements.body;

    
    return (
        
        <>
            {props.layoutSection === "AboutTheProgramme" &&

                <LayoutAboutTheProgramme 
                    mode={_mode}
                    title={_title}
                    mediaAssets={_mediaAssets}
                    text={_aboutBody}
                /> 
            }

            {props.layoutSection === "FiveFacts" &&

                <Layout5Facts 
                    mode={_mode}
                    title={_title}
                    richText={_richTextElement}
                    mediaAssets={_mediaAssets}
                    oddEvenDisplay={_oddEvenDisplay}
                /> 
            }

            {props.layoutSection === "ExpandedArticle" &&

                <LayoutExpandedArticle
                    mode={_mode}
                    title={_title}
                    mediaAssets={_mediaAssets}
                    oddEvenDisplay={_oddEvenDisplay}
                    richText={_richTextElement}
                /> 
            }

            {props.layoutSection === "InfographicThumbnailArticle" &&

                <LayoutInfographicThumbnailArticle
                    mode={_mode}
                    title={_title}
                    mediaAssets={_mediaAssets}
                    oddEvenDisplay={_oddEvenDisplay}
                    richText={_richTextElement}
                /> 
            }

            {props.layoutSection === "TiledArticle" &&

                <LayoutTiledAsset
                    title={_title}
                    summary={_text}
                    mediaAssets={_mediaAssets}
                    image={_image}
                    richText={_richTextElement}
                    layout={props.layoutSection}
                /> 
            } 

            {props.layoutSection === "AboutGetInvolved" &&
            
                <LayoutGetInvolved
                    mode={_mode}
                    title={_title}
                    mediaAssets={_mediaAssets}
                    oddEvenDisplay={_oddEvenDisplay}
                    richText={_richTextElement}
                />
            
            }      

            {props.layoutSection === "AboutIntroduction" &&
            
                <LayoutAboutIntroduction
                    mode={_mode}
                    title={_title}
                    mediaAssets={_mediaAssets}
                    oddEvenDisplay={_oddEvenDisplay}
                    richText={_richTextElement}
                />
            
            }      
        </>     
    )

}



