import React from "react"
import LayoutCarousel from "./LayoutCarousel.js"

export default function LayoutExpandedArticleCarousel(props) {
   
    return (

        <>
            <LayoutCarousel
                carouselTitle={props.carouselTitle}
                listOfCarouselItems={props.listOfCarouselItems}
                articleIndex={props.articleIndex}
            />  
        </>

    )
}