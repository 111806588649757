import React from "react"
import { IsEmpty } from '../helpers/HelperFunctions.js'
import { Link } from 'gatsby'

export default function Button({link=null, text='', class: classProp='', linkType='Internal', target='_self', label}) {

    if (IsEmpty(link) || IsEmpty(text) || IsEmpty(classProp))
        return null
 
    const keyIdentifier = "key_" + Math.random();

    if (IsEmpty(label))
        return (
            <React.Fragment >

                {(linkType === 'Internal') &&

                    <Link to={link} target={target} className={classProp}>
                        {text}
                    </Link>
                }
                
                {(linkType === 'External') &&

                    <a href={link} target={target} className={classProp}>
                        {text}
                    </a>
                }
                

            </React.Fragment>
        )

    if (!IsEmpty(label))
        return (
            <React.Fragment>

                {(linkType === 'Internal') &&

                    <Link to={link} target={target} className={classProp} aria-label={label}>
                        {text}
                    </Link>
                }
                
                {(linkType === 'External') &&

                    <a href={link} target={target} className={classProp} aria-label={label}>
                        {text}
                    </a>
                }
                
            </React.Fragment>
        )

}