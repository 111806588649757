import React from "react"
import { IsEmpty,IsEmptyArray } from "../../helpers/HelperFunctions"
import ModularContentProcessor from "../StructuredContent/ModularContentProcessor.js";
import RenderMedia from "../AssetManagement/RenderMedia";


export default function LayoutExpandedArticle(props) {
   
    const oddEven = (props.oddEvenDisplay === "Black") ? "ou-expanded-article-container article-odd" : "ou-expanded-article-container article-even";
    const layoutSection = "ExpandedArticle";

    return (

        <>
            {!IsEmpty(props.title) &&
                <h3 className="ou-expanded-article-title">{props.title}</h3>
            }

            <div className={oddEven}>

                <>
                    {!IsEmptyArray(props.mediaAssets) &&
                        <div className="ou-expanded-article-image-container">
                            {/* Display media assets.. May have a combination of iFrameLink, image or both. */}
                            <RenderMedia 
                                    mediaArray={props.mediaAssets} 
                                    showCaptions="true"
                            />
                        </div>
                    }
                    <div className="ou-expanded-article-content">

                        <ModularContentProcessor 
                            richText={props.richText}
                            layoutSection={layoutSection}
                        />
                    
                    </div>
                </>
            </div>

        </>

    )
}